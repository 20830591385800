.AllProductFilterContainer {
    height: auto;
    padding: 32px 0 32px 0;
    display: flex;
    justify-content: space-between;
}

.AllProductFilterDiv {
    width: 50%;
}

.AllProductFilterHeading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    padding: 0px 0px 32px 0px;
    color: #30373e;
}

.AllProductFilterAlignColors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.AllProductFilterAlignColors1 {
    display: flex;
    justify-content: flex-start;
    padding: 16px 0px 0px 0px;
    gap: 32px;
}

.AllProductFilterColorsSize {
    height: 50px;
    width: 50px;
    border-radius: 50px;
}

.AllProductFilterColorsSizeA {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 1px solid #000;
}

.AllProductFilterColorsFont {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
    text-transform: capitalize;
}

.AllProductFilterAlignSizeSlider {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px 0px 0px 0px;
    height: 72px;
    width: 100%;
    gap: 32px;
}

.AllProductFilterAlignPrices {
    display: flex;
    justify-content: space-between;
}

.AllProductFilterPriceBoxes {
    width: 30%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.horizontal-slider {
    width: 100%;
    height: 4px;
}

.horizontal-slider .example-track {
    height: 4px;
}

.example-track.example-track-0 {
    background: #898686;
}

.example-track.example-track-1 {
    background: #c7ab9b;
}

.example-track.example-track-2 {
    background: #898686;
}

.horizontal-slider .example-thumb {
    cursor: pointer;
    background-color: #fff;
    border: 1px double #c7ab9b;
    border-radius: 100%;
    padding: 6px;
    position: absolute;
    top: -5px;
}

@media screen and (max-width: 430px) {
    .selectSettingFilterContainer {
        height: auto;
        padding: 0px 16px 0px 16px;
        display: flex;
        justify-content: space-between;

        flex-direction: column;
        gap: 16px;
    }

    .selectSettingFilterDiv {
        width: 100%;
    }

    .selectSettingFilterHeading {
        font-family: Alike;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 0.025em;
        text-align: left;
        padding: 32px 0px 16px 0px;
        color: #30373e;
    }

    .selectSettingFilterAlignColors {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }

    .selectSettingFilterAlignColors1 {
        display: flex;
        justify-content: flex-start;
        padding: 16px 0px 0px 0px;
        gap: 32px;
    }

    .selectSettingFilterColorsSize {
        height: 28px;
        width: 28px;
        border-radius: 50px;
        border: 1px solid;
    }

    .selectSettingFilterColorsSizeA {
        height: 28px;
        width: 28px;
        border-radius: 50px;
        border: 1px solid #000;
    }

    .selectSettingFilterColorsFont {
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        color: #30373e;
        text-transform: capitalize;
    }

    .selectSettingFilterAlignSizeSlider {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px 0px 0px 0px;
        height: 64px;
        width: 100%;
        gap: 16px;
    }

    .selectSettingFilterAlignPrices {
        display: flex;
        justify-content: space-between;
    }

    .selectSettingFilterPriceBoxes {
        width: 30%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
    }

    .horizontal-slider {
        width: 100%;
        height: 4px;
    }

    .horizontal-slider .example-track {
        height: 4px;
    }

    .example-track.example-track-0 {
        background: #898686;
    }

    .example-track.example-track-1 {
        background: #c7ab9b;
    }

    .example-track.example-track-2 {
        background: #898686;
    }

    .horizontal-slider .example-thumb {
        cursor: pointer;
        background-color: #fff;
        border: 1px double #c7ab9b;
        border-radius: 100%;
        padding: 6px;
        position: absolute;
        top: -5px;
    }
}