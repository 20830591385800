.sd3Container {
  height: auto;
  padding: 0px 123px 72px 123px;
}

@media screen and (max-width: 430px) {
  .sd3Container {
    height: auto;
    padding: 0px 16px 32px 16px;
  }
}
