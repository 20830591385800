.selectDiamondPage1Container {
  height: auto;
  padding: 72px 123px 72px 123px;
}

.selectDiamondPage1heading {
  font-family: Alike;
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 0px 0px;
}

.selectDiamondPage1AlignData {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin: 32px 0px 32px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px double #eaeaea;
}

.selectDiamondPage1ImgSize {
  height: auto;
  width: auto;
}

.selectDiamondPage1Managedata {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selectDiamondAlignGridDivs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 10px 0px 0px;
}

.selectDiamondAlignGridDivs1 {
  width: 48%;
}

.selectDiamondAlignGridDivsData {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 0px 32px 0px;
  column-gap: calc(2%);
  row-gap: 16px;
}

.selectDiamondColorSize {
  background: #fff;
  border: 1px solid #818181;
  height: 32px;
  width: calc(10%);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
}

.selectDiamondCalritySize {
  background: #fff;
  border: 1px solid #818181;
  height: 32px;
  width: calc(10%);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
}

.selectDiamondCutSize {
  background: #fff;
  border: 1px solid #818181;
  height: 32px;
  width: calc(33%);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
}

.selectDiamondFlourSize {
  background: #fff;
  border: 1px solid #818181;
  height: 32px;
  width: calc(23%);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
}

.selectDiamondPolishSize {
  background: #fff;
  border: 1px solid #818181;
  height: 32px;
  width: calc(23%);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
}

.selectDiamondCertificateSize {
  background: #fff;
  border: 1px solid #818181;
  height: 32px;
  width: calc(10%);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
}

.selectDiamondSymmSize {
  background: #fff;
  border: 1px solid #818181;
  height: 32px;
  width: calc(23%);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
}
.selectDiamondAlignGridDivsMore {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  /* padding: 16px 10px 0px 0px; */
  /* margin-top: -10vh; */
}


.selectDiamondMoreBtn {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  background-color: #F8EDE1;
  padding: 0px 0px 32px 0px;
}

@media screen and (max-width: 430px) {
  .selectDiamondPage1Container {
    height: auto;
    padding: 0px 16px 0px 16px;
  }

  .selectDiamondPage1heading {
    font-family: Alike;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .selectDiamondPage1AlignData {
    width: 100%;
    background-color: #fff;
    height: auto;
    margin: 16px 0px 16px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border: 0px double;
    column-gap: calc(1.3%);
    row-gap: 8px;
  }

  .selectDiamondPage1ImgSize {
    height: auto;
    width: 100%;
  }

  .selectDiamondPage1Managedata {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(24%);
  }

  .selectDiamondAlignGridDivs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 4px 10px 0px 0px;
  }

  .selectDiamondAlignGridDivs1 {
    width: 100%;
    padding: 16px 0px 0px 0px;
  }

  .selectDiamondAlignGridDivsData {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 16px 0px 0px 0px;
    column-gap: calc(2%);
    row-gap: 8px;
  }

  .selectDiamondColorSize {
    background: #fff;
    height: 16px;
    width: calc(10%);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
  }

  .selectDiamondCalritySize {
    background: #fff;
    height: 16px;
    width: calc(18%);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
  }

  .selectDiamondCutSize {
    background: #fff;
    height: 16px;
    width: calc(30%);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
  }

  .selectDiamondFlourSize {
    background: #fff;
    height: 16px;
    width: calc(23%);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
  }

  .selectDiamondPolishSize {
    background: #fff;
    height: 16px;
    width: calc(23%);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
  }

  .selectDiamondCertificateSize {
    background: #fff;
    height: 16px;
    width: calc(10%);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
  }

  .selectDiamondSymmSize {
    background: #fff;
    height: 16px;
    width: calc(23%);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
  }

  .selectDiamondAlignGridDivsMore {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 0px 10px 0px 0px;
    margin-top: 0;
  }

  .selectDiamondMoreBtn {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    background-color: #F8EDE1;
    padding: 0px 0px 0px 0px;
  }
}
