.advertContainer {
  background-color: #ffe58c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  z-index: 999;
  position: fixed;
  width: 100%;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .advertContainer {
    background-color: #ffe58c;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    z-index: 999;
    position: fixed;
    width: auto;
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 0px 12px 0px 12px;
  }
}
