.giftContainerAbc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.giftContainerAbc1 {
  position: absolute;
  left: 123px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.giftContainerAbcHead {
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.giftContainerAbcText {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 50%;
}

.giftContainerAbcbtn {
  background-color: #30373e;
  color: #fff;
  font-family: Alike;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  width: 30%;
  height: 42px;
  border: none;
}

@media screen and (max-width: 430px) {
  .giftContainerAbc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .giftContainerAbc1 {
    position: absolute;
    left: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    top: calc(20%);
    align-items: center;
  }

  .giftContainerAbcHead {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .giftContainerAbcText {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 84%;
  }

  .giftContainerAbcbtn {
    background-color: #30373e;
    color: #fff;
    font-family: Alike;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.025em;
    text-align: center;
    width: 50%;
    height: 42px;
    border: none;
  }
}
