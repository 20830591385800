.auContainer {
  height: auto;
  position: relative;
}

.au1Div {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.au2Div {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.au3Div {
  position: relative;
  display: flex;
  align-items: center;
}

.au1Div1 {
  position: absolute;
  right: 0px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.au3Div1 {
  position: absolute;
  left: 123px;
  width: 46%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.auImg {
  width: 100%;
  height: auto;
}

.au1HeadA {
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.au1Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.au1Text {
  font-family: open sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 80%;
}

.au1Btn {
  width: 60%;
  height: 42px;
  font-family: Alike;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  background-color: #fff;
  border: none;
}

.auHeading {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 97px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 40%;
  position: absolute;
  bottom: 72px;
  right: 32px;
}

.auContainer1 {
  text-align: center;
  padding: 72px 123px 0px 123px;
}

.auHeading1 {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.auText {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 32px 0px 0px 0px;
}

.auContainer2 {
  padding: 72px 123px 72px 123px;
  display: flex;
  justify-content: space-between;
}

.auContainer2Div {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.auContainer2Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.auContainer2Text {
  font-family: open sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.auContainer2Btn {
  border: 1px solid #30373e;
  background-color: #fff;
  width: 60%;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .auContainer2Div {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .auContainer2Head {
    font-family: Alike;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
  }

  .auContainer2Text {
    font-family: open sans;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .auContainer2Btn {
    border: 1px solid #30373e;
    background-color: #fff;
    width: 60%;
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }
}

@media screen and (max-width: 430px) {
  .auContainer {
    height: auto;
    position: relative;
  }

  .auImg {
    width: 100%;
    height: 240px;
  }

  .au1Div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .au2Div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .au2Div1 {
    position: absolute;
    right: auto;
    width: auto;
    top: calc(12%);
    left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .au1Div1 {
    position: absolute;
    right: 0px;
    width: auto;
    top: auto;
    left: auto;
    align-items: center;
  }

  .auImg {
    width: 100%;
    height: auto;
  }

  .au1HeadA {
    font-family: Alike;
    font-size: 3rem;
    font-weight: 400;
    line-height: 4rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px;
  }

  .au1Head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .au2Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px;
    width: 90%;
  }

  .au2Text {
    font-family: open sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 84%;
  }

  .au2Btn {
    width: 60%;
    height: 42px;
    font-family: Alike;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    background-color: #fff;
    border: none;
  }

  /* .auHeading {
    font-family: Alike;
    font-size: 54px;
    font-weight: 400;
    line-height: 97px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 40%;
    position: absolute;
    bottom: 72px;
    right: 32px;
  } */

  .auHeading {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 60%;
    position: absolute;
    bottom: 32px;
    right: 32px;
  }

  .auContainer1 {
    text-align: center;
    padding: 32px 16px 32px 16px;
  }

  .auHeading1 {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .auText {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 32px 0px 0px 0px;
  }

  .auContainer2 {
    padding: 32px 16px 32px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 32px;
  }

  .auContainer2Div {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
  }

  .auContainer2Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }

  .auContainer2Text {
    font-family: open sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .auContainer2Btn {
    border: 1px solid #30373e;
    background-color: #fff;
    width: 50%;
    font-family: Alike;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }
}
