.ring2Container {
  padding: 72px 123px 0px 123px;
  display: flex;
  justify-content: space-between;
  gap: 0px;
}

.rings2Div {
  width: 49%;
}

.ring2Img {
  width: 100%;
}

@media screen and (max-width: 430px) {
  .ring2Container {
    padding: 48px 32px 0px 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 32px;
  }

  .rings2Div {
    width: 100%;
  }

  .ring2Img {
    width: 100%;
  }
}
