.erContainer1 {
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
}

.erMainImg {
  width: 100%;
}

.erContainer1AlignDiv {
  position: absolute;
  width: 36%;
  top: calc(30%);
  right: 32px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.erMainHead {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.erMainText {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: justify;
  color: #30373e;
}

.erBtn1 {
  width: 80%;
  border: none;
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 10px 0px 10px 0px;
  background-color: #fff;
}

.erBtn2 {
  width: 80%;
  background-color: #30373e;
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  border: none;
  padding: 10px 0px 10px 0px;
}

.erContainer2 {
  padding: 72px 123px 72px 123px;
}

.er2MainHead {
  font-family: Alike;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.er2AlignData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.er2Div {
  width: calc(24%);
  text-align: center;
}

.er2Img {
  width: 100%;
}

.er2Text {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .erContainer1 {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
  }

  .erMainImg {
    width: 100%;
  }

  .erContainer1AlignDiv {
    position: absolute;
    width: 100%;
    top: calc(20%);
    left: auto;
    right: auto;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
  }

  .erMainHead {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .erMainText {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    width: 84%;
    color: #30373e;
  }

  .erBtn1 {
    width: 240px;
    border: 1px solid #30373e;
    font-family: Alike;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.025em;
    text-align: center;
    padding: 4px 0px 4px 0px;
    background-color: transparent;
  }

  .erBtn2 {
    width: 240px;
    background-color: #30373e;
    font-family: Alike;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #fff;
    border: none;
    padding: 4px 0px 4px 0px;
  }

  .erContainer2 {
    padding: 32px 16px 32px 16px;
  }

  .er2MainHead {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 32px 0px;
  }

  .er2AlignData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .er2Div {
    width: calc(24%);
    text-align: center;
  }

  .er2Img {
    width: 90%;
    margin: auto;
  }

  .er2Text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }
}
