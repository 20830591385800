.odpDivider {
  margin: 0px 123px 32px 123px;
  border: 1px solid #efdada;
}

.odpH {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: 32px 0px 16px 123px;
}

.odpAddress {
  background-color: #f9f8f6;
  margin: 0px 123px 48px 123px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.odpAddressCName {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: 0px;
  padding: 0px 0px 16px 0px;
}

.odpAddressAdrs {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}
.odpAddressType {
  font-family: Alike;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  background-color: #f7eded;
  padding: 12px 48px 12px 48px;
  border-radius: 48px;
  text-transform: capitalize;
}
.odpOrderStatus{
  margin: 32px 0px 16px 123px;
  display: flex;
  align-items: center;
}

.odpOrderCircle{
  width: 30px;
  height: 30px;
  background-color: #EFDADA;
  border-radius: 50%;
  position: absolute;
}
.odpOrderLine{
  height: 10px;
  background-color: #EFDADA80;
  width: 100%;
}
.odpOrderCircleLine{
  display: flex;
  align-items: center;
}
.odpOrderCircleContainer{
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.odpOrderStatusText{
  font-family: Open Sans Hebrew;
  color: #30373E;
  font-weight: 400;
  font-size: 1.25rem;
  margin-left: -2vw;
}
.odpmopOrders {
  display: flex;
  justify-content: space-between;
  margin: 32px 123px 0px 123px;
  background-color: #f9f8f6;
  padding: 16px;
}
.odpmopOrders1 {
  display: flex;
  gap: 96px;
}
.odpmopOrderH {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #555d66;
  text-transform: uppercase;
}
.odpmopAlignP {
  padding: 16px 123px 32px 123px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.odpacBtndiv{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.odpmopView1 {
  width: auto;
  padding: 8px 16px;
  border: 1px solid #30373e;
  background-color: #fff;
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  text-transform: capitalize;
  cursor: pointer;
  opacity: 1;
}
.odpContainer{
  display: flex;
}
.odpH2{
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #58595b;
  margin: 32px 0px 16px 123px;
}

@media screen and (max-width:430px) {
  .odpmopOrders{
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 10px;
  }
  .odpmopOrders1{
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .odpmopOrderH{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .odpmopAlignP{
    padding: 0 10px;
    flex-direction: column;
    gap: 10px;
  }
  .odpacBtndiv{
    flex-direction: row;
    gap: 5px;
  }
  .odpmopView1{
    padding: 0;
    line-height: 14px;
    width: 25%;
    font-size: 12px;
  }
  .odpAddress{
    margin: 0;
  }
  .odpOrderStatus{
    margin: 0 10px;
  }
  .odpOrderStatusText{
    font-size: 11px;
  }
}