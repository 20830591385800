.bg4Container {
  padding: 0px 123px 72px 123px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg4Head {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.bg4Img {
  width: 100%;
}

.bg4MHead {
  font-family: Alike;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 32px 0px 32px 0px;
}

.bg4Text {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 0px 0px 72px 0px;
  width: 70%;
}

.bg4Img1 {
  width: 50%;
}

@media screen and (max-width: 430px) {
  .bg4Container {
    padding: 0px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bg4Head {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .bg4Img {
    width: 100%;
  }

  .bg4MHead {
    font-family: Alike;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 16px 0px 16px 0px;
  }

  .bg4Text {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 0px 0px 32px 0px;
    width: 70%;
  }

  .bg4Img1 {
    width: 80%;
  }
}
