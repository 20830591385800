.dpGap {
  padding: 64px 0px 0px 0px;
}

@media screen and (min-width: 1100px) and (max-width: 1440px) {
  .dpGap {
    padding: 96px 0px 0px 0px;
  }
}

@media screen and (min-width: 1441px) {
  .dpGap {
    padding: 116px 0px 0px 0px;
  }
}

@media screen and (max-width: 430px) {
  .dpGap {
    padding: 64px 0px 0px 0px;
  }
}
