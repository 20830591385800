.allProductpageMaindiv {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    position: relative;
    padding: 0 5vw 0 5vw;
}

.allProductSectionTitle {
    margin-top: 25vh;
    font-family: Alike;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #30373E;
    letter-spacing: 2.5%;
    font-weight: 400;
}

.allppsbsimgdiv {
    margin-top: 5vh;
    width: 100%;
    height: 100px;
    border: 1px solid #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.allppsimg {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.allppProductdiv {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: center;
    gap: 6vw;
}

.allppsimgtitle {
    font-family: Open Sans Hebrew;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.rem;
    letter-spacing: 0em;
    text-align: left;
    color: #30373E;
}

.allppProductTitle {
    font-family: Alike;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.025em;
    color: #000000;
}

.allppProductColor {
    font-family: Open Sans Hebrew;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.025em;
    color: #717171;
}

.allppProductPrice {
    font-family: Open Sans Hebrew;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.68rem;
    letter-spacing: 0em;
    color: #30373E;
}

@media screen and (max-width: 430px) {
    .allProductSectionTitle {
        margin-top: 10vh;
    }
}