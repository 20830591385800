.settingProductPageContainer {
  height: auto;
  padding: 32px 123px 72px 123px;
  display: flex;
  justify-content: space-between;
}

.settingProductPageDiv1 {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.settingProductImgDiv1 {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9dfd2;
}

.settingProductImgDiv2 {
  display: flex;
  justify-content: space-between;
}

.settingProductImg1 {
  width: 100%;
  height: auto;
}

.settingProductImg2 {
  width: 72px;
  height: 72px;
  padding: 8px 44px 8px 44px;
  background-color: #e9dfd2;
}

.settingProductPageDiv2 {
  width: 49%;
  padding: 4px 0px 0px 16px;
}

.settingProductPageDiv2Heading {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.settingProductPageDiv2Texts {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 8px 0px 0px 0px;
}

.settingProductPageDiv2Heading1 {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 16px 0px 16px 0px;
}

.productSettingFilterAlignColors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.productSettingFilterAlignColors1 {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0px 0px 0px;
  gap: 32px;
}

.productSettingFilterColorsSize {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  border: none;
}

.productSettingFilterColorsSizeA {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  border: 1px solid #000000;
}

.productsSettingFilterColorsFont {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
  padding: 4px 0px 0px 0px;
  text-transform: capitalize;
}

.productSettingPrice {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  padding: 16px 0px 0px 0px;
}

.settingProductPageDiv2Texts1 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 4px 0px 0px 0px;
}

.productSettingbtn {
  width: 100%;
  height: 56px;
  background: #30373e;
  text-align: center;
  color: #fff;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  margin: 24px 0px 0px 0px;
  border: none;
}

.productSettingRingDetails {
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 48px 0px 0px 0px;
}

.productSettingRingDetails1 {
  display: flex;
  justify-content: flex-start;
  gap: 72px;
  position: relative;
}

.productSettingDivider {
  background: linear-gradient(
    90deg,
    #e2e2e2 0%,
    rgba(226, 226, 226, 0) 0.01%,
    #e2e2e2 47.22%,
    rgba(226, 226, 226, 0) 96.83%
  );
  height: 1px;
  width: 180px;
  rotate: -90deg;
  position: absolute;
  top: 86px;
  left: 30px;
}

.productSettingAlignDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0px 0px 0px;
  color: #30373e;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
}

.alignReviewOrder {
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 8px 0px 8px 0px;
}

.alignReviewOrderdiv {
  width: 100%;
}

.settingProductPageDiv2HeadingReview {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.alignReviewOrder1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.alignReviewOrderPName {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.alignReviewOrderPgrade {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.productSettingPrice1 {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.productSettingFilterAlignShapes {
  display: flex;
  flex-wrap: wrap;

  background: #000;
  gap: 16px;
}

.productSettingShapesImages {
  width: calc(40%);
}

@media screen and (max-width: 430px) {
  .settingProductPageContainer {
    height: auto;
    padding: 16px 16px 16px 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .settingProductPageDiv1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .settingProductImgDiv1 {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9dfd2;
    width: 100%;
  }

  .settingProductImgDiv2 {
    display: flex;
    justify-content: space-between;
  }

  .settingProductImg1 {
    width: 100%;
    height: auto;
  }

  .settingProductImg2 {
    width: calc(12%);
    height: auto;
    padding: 8px 32px 8px 32px;
    background-color: #e9dfd2;
  }

  .settingProductPageDiv2 {
    width: 100%;
    padding: 16px 0px 0px 0px;
  }

  .settingProductPageDiv2Heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .settingProductPageDiv2Texts {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 16px 0px 0px 0px;
  }

  .settingProductPageDiv2Heading1 {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 32px 0px 16px 0px;
  }

  .productSettingFilterAlignColors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .productSettingFilterAlignColors1 {
    display: flex;
    justify-content: flex-start;
    padding: 8px 0px 0px 0px;
    gap: 32px;
  }

  .productSettingFilterColorsSize {
    height: 28px;
    width: 28px;
    border-radius: 32px;
  }

  .productsSettingFilterColorsFont {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
    padding: 4px 0px 0px 0px;
    text-transform: capitalize;
  }

  .productSettingPrice {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    padding: 32px 0px 0px 0px;
  }

  .settingProductPageDiv2Texts1 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 32px 0px;
  }

  .productSettingbtn {
    width: 100%;
    height: 56px;
    background: #30373e;
    text-align: center;
    color: #fff;
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: center;
    margin: 12px 0px 0px 0px;
    border: none;
  }

  .productSettingRingDetails {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 48px 0px 0px 0px;
  }

  .productSettingRingDetails1 {
    display: flex;
    justify-content: flex-start;
    gap: 72px;
    position: relative;
  }

  .productSettingDivider {
    background: linear-gradient(
      90deg,
      #e2e2e2 0%,
      rgba(226, 226, 226, 0) 0.01%,
      #e2e2e2 47.22%,
      rgba(226, 226, 226, 0) 96.83%
    );
    height: 1px;
    width: 180px;
    rotate: -90deg;
    position: absolute;
    top: 86px;
    left: 30px;
  }

  .productSettingAlignDetails {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0px 0px 0px;
    color: #30373e;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;
  }
}
