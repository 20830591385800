.footerContainer {
  padding: 72px 123px 72px 123px;
  display: flex;
  justify-content: space-between;
  background-color: #f8f6f4;
}

.footerheading {
  font-family: open Sans;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  padding: 0px 0px 8px 0px;
  color: #000;
}

.footerTexts {
  font-family: open Sans;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  padding: 4px 0px 4px 0px;
  opacity: 1;
  cursor: pointer;
}

.footerTexts:hover {
  opacity: 0.8;
  cursor: pointer;
}

.footerAlignIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0px 0px 32px 0px;
  background-color: #f8f6f4;
}

.footerNote {
  padding: 8px 0px 8px 0px;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
  background-color: #f9e5e9;
}

@media screen and (max-width: 430px) {
  .footerContainer {
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
}
