.mop {
  padding: 148px 123px 0px 123px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.mopH {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.mopA {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #be5b00;
}

.mopDiv {
  padding: 32px 123px 0px 123px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mopDivH {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.mopDiv1 {
  display: flex;
  gap: 16px;
  align-items: center;
}

.mopDiv2 {
  display: flex;
  gap: 16px;
  align-items: center;
  border: 1px solid #30373e;
  padding: 4px;
  border-radius: 8px;
}

.mopText {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  border: none;
}

.mopButton {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #fff;
  padding: 6px 16px 6px 16px;
  background-color: #30373e;
  border: none;
}

.mopDivider {
  border: 1px solid #efdada;
  position: absolute;
  bottom: 16px;
  z-index: 0;
  left: 123px;
  right: 123px;
}

.mopR {
  position: relative;
  padding: 32px 123px 0px 123px;
}

.mopAlignSub {
  padding: 0px 0px 16px 0px;
  display: flex;
  justify-content: flex-start;
  gap: 94px;
}

.mopSubHA {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 16px 0px;
  border-width: 0px 0px 2px 0px;
  border-color: #30373e;
  border-style: solid;
  z-index: 1;
  cursor: pointer;
  opacity: 1;
}

.mopSubHA:active {
  opacity: 0.8;
}

.mopSubHU {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  cursor: pointer;
  opacity: 1;
}

.mopSubHU:active {
  opacity: 0.8;
}

.mopOrders {
  display: flex;
  justify-content: space-between;
  margin: 32px 123px 0px 123px;
  background-color: #f9f8f6;
  padding: 16px;
}

.mopOrders1 {
  display: flex;
  gap: 96px;
}

.mopOrderH {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #555d66;
  text-transform: uppercase;
}

.mopAlignP {
  padding: 16px 123px 32px 123px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mopAlignP1 {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.mopAlignP2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.mopName {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: justify;
  color: #30373e;
  text-transform: capitalize;
}
.mopName2{
  font-family: Open Sans Hebrew;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: justify;
  color: #555D66;
  text-transform: capitalize;
}

.mopView {
  width: 30%;
  height: 42px;
  border: none;
  background-color: #ffe58c;
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  text-transform: capitalize;
  cursor: pointer;
  opacity: 1;
}

.mopView:active {
  opacity: 0.8;
}

.mopView1 {
  width: auto;
  padding: 8px 16px 8px 16px;
  border: 1px solid #30373e;
  background-color: #fff;
  font-family: Alike;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  text-transform: capitalize;
  cursor: pointer;
  opacity: 1;
}

.mopView1:active {
  opacity: 0.8;
}

@media screen and (max-width: 430px) {
  .mop{
    padding: 10px 0;
  }
  .mopDiv{
    padding: 50px 10px 0;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .mopR{
    padding: 15px 0;
  }
  .mopAlignSub{
    justify-content: space-between;
    gap: 0;
    padding: 0 10px;
  }
  .mopSubHA{
    font-size: 14px;
    line-height: 20px;
    padding: 0;
  }
  .mopSubHU{
    font-size: 14px;
    line-height: 20px;
  }
  .mopDivider {
    position: relative;
    top: 5px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .mopAlignP{
    padding: 10px;
  }
  .mopName{
    font-size: 14px;
    line-height: 15px;
  }
  .mopView{
    padding: 0;
    margin: 50px 10px 0 0;
    height: 25px;
    font-size: 12px;
    line-height: normal;
    position: absolute;
    right: 0;
  }
  .mopButton{
    font-size: 14px;
    line-height: 15px;
    padding: 10px 3px;
  }
}