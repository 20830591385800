.sd1Container {
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 123px 72px 123px;
}

.sd1Head {
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.sd1AlignDiv {
  display: flex;
  gap: 8px;
  padding: 32px 0px 0px 0px;
}

.sd1Divs {
  border: 1px double #eaeaea;
  padding: 16px 48px 16px 48px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .sd1Container {
    height: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 16px 32px 16px;
  }

  .sd1Head {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .sd1AlignDiv {
    display: flex;
    gap: 8px;
    padding: 32px 0px 0px 0px;flex-direction: column;
  }

  .sd1Divs {
    border: 1px double #eaeaea;
    padding: 8px 64px 8px 64px;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }
}
