.home2Container {
  display: flex;
  flex-direction: column;
  padding: 72px 123px 72px 123px;
}

.home1Container2-heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0px 0px 32px 0px;
  color: #30373e;
}

.alignRings {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.home1Container2-div {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 140px;
  width: 140px;
  gap: 0px;
}

.home2Img {
  width: 50%;
  margin: auto;
}

.home1Container2-text {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

@media only screen and (max-width: 430px) {
  .home2Container {
    display: flex;
    flex-direction: column;
    padding: 32px 16px 32px 16px;
  }

  .home1Container2-heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    padding: 32px 0px 16px 0px;
  }

  .alignRings {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .home1Container2-div {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    width: calc(24%);
    gap: 0px;
  }

  .hom1Img2 {
    width: 64px;
    height: 64px;
  }

  .home1Container2-text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }

  .home2Img {
    width: 50%;
    margin: auto;
  }
}
