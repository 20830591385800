.dn1Container {
  height: auto;
  position: relative;
  width: 100%;
}

.dn1Img {
  width: 100%;
}

.dn1MainDiv {
  position: relative;
  display: flex;
  justify-content: center;
}

.dn1MainDiv1 {
  position: absolute;
  bottom: calc(10%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dn1Heads1 {
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  width: 70%;
}

.dn1Heads2 {
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  width: 70%;
}

.dn1Div {
  position: absolute;
  bottom: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dn1Head {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #ffffff;
  width: 56%;
}

.dn1Text {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #ffffff;
  width: 60%;
}

.dn1Container1 {
  padding: 72px 123px 72px 123px;
  display: flex;
  justify-content: space-between;
}

.dn1Container1-div {
  width: 49%;
}

.dn1M1Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.dn1M1Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 32px 0px 0px 0px;
}

@media screen and (max-width: 430px) {
  .dn1Container {
    height: auto;
    position: relative;
    width: 100%;
  }

  .dn1MainDiv {
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .dn1MainDiv1 {
    position: absolute;
    top: calc(10%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dn1Heads1 {
    font-family: Alike;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 90%;
  }
  
  .dn1Heads2 {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: hsl(0, 0%, 100%);
    width: 90%;
  }
  

  .dn1Img {
    width: 100%;
  }

  .dn1Div {
    position: absolute;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dn1Head {
    font-family: Alike;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #ffffff;
    width: 70%;
  }

  .dn1Text {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #ffffff;
    width: 80%;
  }

  .dn1Container1 {
    padding: 32px 16px 32px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .dn1Container1-div {
    width: 100%;
  }

  .dn1M1Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .dn1M1Text {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 16px 0px 16px 0px;
  }
}
