.home3Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 123px 123px 48px 123px;
}

.home3ContainerDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}

.home3ContainerDivA {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 32px;
  bottom: 32px;
}

.home3ContainerDivB {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 32px;
  top: 32px;
}

.home3ContainerH {
  color: #30373e;
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  width: 80%;
}

.home3ContainerT {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
  width: 60%;
}

@media only screen and (max-width: 430px) {
  .home3Container {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 32px 16px 0px 16px;
  }

  .home3ContainerDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .home3ContainerDivA {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 32px;
    left: auto;
    bottom: 32px;
    align-items: center;
  }

  .home3ContainerDivB {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 32px;
    left: auto;
    top: 32px;
    align-items: center;
  }

  .home3ContainerH {
    color: #30373e;
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    width: 100%;
  }

  .home3ContainerT {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015em;
    text-align: center;
    color: #30373e;
    width: 84%;
  }
}
