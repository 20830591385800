.selectDiamondContainer {
  height: auto;
  padding: 72px 123px 0px 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectDiamondFirstHeading {
  font-family: Alike;
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 0px 0px 0px 0px;
}

.selectDiamondAlignDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0px 16px 0px;
  width: 70%;
}

.selectDiamondAlignDivs {
  width: calc(32%);
  height: 40px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30373e;
  background: #fff;
  border: 1px solid #30373e;
}

@media screen and (max-width: 430px) {
  .selectDiamondContainer {
    height: auto;
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .selectDiamondFirstHeading {
    font-family: Alike;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: center;
    padding: 0px 0px 0px 0px;
  }

  .selectDiamondAlignDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px 0px 16px 0px;
    width: 60%;
    gap: 8px;
  }

  .selectDiamondAlignDivs {
    width: 100%;
    height: 40px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #30373e;
    background: #fff;
  }
}
