.cudContainer {
  height: auto;
  position: relative;
  margin-top: -50px;
}

.cudAlign {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 32px;
}
.cudCloseIcon{
    margin-top: 8vh;
    right: 0;
    position: absolute;
    cursor: pointer;
}

.cudImg {
  width: 100%;
  height: auto;
}

.cudHead {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.cudText {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 50%;
}

.cudMainHead {
  font-family: Alike;
  font-size: 48px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 72px 0px 0px 0px;
}

.cudAlignInput {
  padding: 48px 123px 16px 123px;
  display: flex;
  justify-content: space-between;
  gap: 4vw;
}

.cudAlignInput1 {
  width: 49%;
}

.cudInput1 {
  width: 100%;
  border: 1px double #f5e2d7;
  height: 42px;
}

.cudName {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 8px 0px;
}

.cudAlignInput2 {
  padding: 0px 123px 16px 123px;
  display: flex;
  flex-direction: column;
}

.cudTextArea {
  width: 100%;
  border: 1px double #f5e2d7;
  height: auto;
}

.cudAlignInput3 {
  padding: 32px 123px 0px 123px;
  display: flex;
  flex-direction: column;
}

.cudBtnss {
  background-color: #30373e;
  color: #ffffff;
  height: 42px;
  width: 100%;
}

.cudContainer2 {
  padding: 72px 123px 72px 123px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cudContainer2Div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  height: 200px;
  gap: 16px;
}

.cudContainer2Head {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.cudContainer2Text {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .cudContainer {
    height: auto;
    position: relative;
  }

  .cudImg {
    width: 100%;
    height: 320px;
  }
  .cudAlign {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: calc(8%);
    gap: 32px;
  }

  .cudHead {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .cudText {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 90%;
  }

  .cudMainHead {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 32px 0px 0px 0px;
  }

  .cudAlignInput {
    padding: 32px 24px 16px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .cudAlignInput1 {
    width: auto;
  }

  .cudInput1 {
    width: 100%;
    border: 1px double #f5e2d7;
    height: 42px;
  }

  .cudName {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 8px 0px;
  }

  .cudAlignInput2 {
    padding: 0px 24px 16px 16px;
    display: flex;
    flex-direction: column;
  }

  .cudTextArea {
    width: 100%;
    border: 1px double #f5e2d7;
    height: auto;
  }

  .cudAlignInput3 {
    padding: 32px 16px 0px 16px;
    display: flex;
    flex-direction: column;
  }

  .cudBtnss {
    background-color: #30373e;
    color: #ffffff;
    height: 42px;
    width: 100%;
  }

  .cudContainer2 {
    padding: 32px 16px 32px 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 32px;
  }

  .cudContainer2Div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 4px;
  }

  .cudContainer2Head {
    font-family: Alike;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .cudContainer2Text {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }
}
