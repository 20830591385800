.cuContainer {
  height: auto;
  position: relative;
}

.cuAlign {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: calc(12%);
  gap: 32px;
}

.cuImg {
  width: 100%;
  height: auto;
}

.cuHead {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.cuText {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 50%;
}

.cuMainHead {
  font-family: Alike;
  font-size: 48px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 72px 0px 0px 0px;
}

.cuAlignInput {
  padding: 48px 123px 16px 123px;
  display: flex;
  justify-content: space-between;
}

.cuAlignInput1 {
  width: 49%;
}

.cuInput1 {
  width: 100%;
  border: 1px double #f5e2d7;
  height: 42px;
}

.cuName {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 8px 0px;
}

.cuAlignInput2 {
  padding: 0px 123px 16px 123px;
  display: flex;
  flex-direction: column;
}

.cuTextArea {
  width: 100%;
  border: 1px double #f5e2d7;
  height: auto;
}

.cuAlignInput3 {
  padding: 32px 123px 0px 123px;
  display: flex;
  flex-direction: column;
}

.cuBtnss {
  background-color: #30373e;
  color: #ffffff;
  height: 42px;
  width: 100%;
}

.cuContainer2 {
  padding: 72px 123px 72px 123px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cuContainer2Div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  height: 200px;
  gap: 16px;
}

.cuContainer2Head {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.cuContainer2Text {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .cuContainer {
    height: auto;
    position: relative;
  }

  .cuImg {
    width: 100%;
    height: 320px;
  }
  .cuAlign {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: calc(8%);
    gap: 32px;
  }

  .cuHead {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .cuText {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 90%;
  }

  .cuMainHead {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 32px 0px 0px 0px;
  }

  .cuAlignInput {
    padding: 32px 24px 16px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .cuAlignInput1 {
    width: auto;
  }

  .cuInput1 {
    width: 100%;
    border: 1px double #f5e2d7;
    height: 42px;
  }

  .cuName {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 8px 0px;
  }

  .cuAlignInput2 {
    padding: 0px 24px 16px 16px;
    display: flex;
    flex-direction: column;
  }

  .cuTextArea {
    width: 100%;
    border: 1px double #f5e2d7;
    height: auto;
  }

  .cuAlignInput3 {
    padding: 32px 16px 0px 16px;
    display: flex;
    flex-direction: column;
  }

  .cuBtnss {
    background-color: #30373e;
    color: #ffffff;
    height: 42px;
    width: 100%;
  }

  .cuContainer2 {
    padding: 32px 16px 32px 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 32px;
  }

  .cuContainer2Div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 4px;
  }

  .cuContainer2Head {
    font-family: Alike;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .cuContainer2Text {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }
}
