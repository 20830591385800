.jw2Div {
  position: relative;
  display: flex;
  align-items: center;
}

.jw2Div1 {
  position: absolute;
  width: 40%;
  right: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jw2Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.jw2Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .jw2Div {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  .jw2Div1 {
    position: absolute;
    width: auto;
    right: 0px;
    left: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
