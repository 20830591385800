.mainContainer{
    margin: 2vh 8vw;
}
.imageContainer{
    display: flex;
}
.crContainerRight{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-left: 12vw;
}
.crHeadText{
    font-family: Alike;
    font-size: 36px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373E;
}
.reviewBagImag1{
    height: 20px;
    width: 20px;
}
.crRingContainer{
    display: flex;
    gap: 16px;
    margin-top: 2vh;
}
.crText{
    font-family: Open Sans Hebrew;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: justify;
    color: #30373E;
}
.crPriceText{
    font-family: Opens Sans Hebrew;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #30373E;
    margin-top: 10px;
}
.crSubText{
    font-family: Open Sans Hebrew;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.025em;
    font-weight: 400;
    color: #30373E;
}
.crSubText2{
    font-family: Alike;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.025em;
    font-weight: 400;
    color: #30373E;
    margin-top: 20px;
}
.crBtn1{
    margin-top: 2vh;
    background-color: #fff;
    outline: none;
    border: 2px solid #30373E;
    height: 50px;
    font-family: Alike;
    font-size: 22px;
    font-weight: 400;
    color: #30373E;
}