/* .selectSettingViewContainer {
 padding: 72px 123px 72px 123px;
} */

.selectSettingViewHeading {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.selectSettingViewAlign {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: calc(4%);
}

.selectSettingViewDiv {
  width: calc(22%);
}
.selectSettingViewAlignSliderMobile{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: calc(4%);
}

@media screen and (max-width: 430px) {
  .selectSettingViewHeading {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .selectSettingViewAlign {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
  }

  .selectSettingViewDiv {
    width: calc(48%);
  }
}
