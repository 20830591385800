.bg1Container {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.bg1AlignData {
  position: relative;
  display: flex;
  align-items: center;
}

.bg1AlignData1 {
  position: absolute;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 123px;
  top: calc(24%);
}

.bg1Head {
  font-family: Alike;
  font-size: 42px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.bg1Text {
  font-family: open sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.bg1AlignBTn {
  display: flex;
  gap: 32px;
}

.bg1BTn {
  background-color: #fff;
  width: 46%;
  height: 42px;
  border: 1px solid #d9d9d9;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #30373e;
}

.bg1Img1 {
  width: 100%;
  height: auto;
}

.bg1Img2 {
  width: 50%;
  position: absolute;
  top: 123px;
  left: 123px;
}

@media screen and (max-width: 430px) {
  .bg1Container {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .bg1Img1 {
    width: 100%;
    height: 280px;
  }

  .bg1Img2 {
    width: 60%;
    position: absolute;
    top: 96px;
    left: 16px;
  }

  .bg1AlignData {
    position: relative;
    display: flex;
    align-items: center;
  }

  .bg1AlignData1 {
    position: absolute;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: auto;
    top: calc(20%);
    align-items: center;
  }

  .bg1Head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }

  .bg1Text {
    font-family: open sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 84%;
  }

  .bg1AlignBTn {
    display: flex;
    gap: 16px;
  }

  .bg1BTn {
    background-color: #fff;
    width: 100%;
    height: 32px;
    border: 1px solid #d9d9d9;
    font-family: open sans;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }
}
