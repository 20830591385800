.cdContainer {
  padding: 148px 123px 72px 123px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}

.cdContainerDiv {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cdAlignHeads {
  display: flex;
  justify-content: space-between;
}

.cdAddNewAddressBTNA {
  background-color: #30373e;
  border: none;
  width: 40%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 1;
  cursor: pointer;
  color: #fff;
  height: 42px;
}

.cdAddNewAddressBTNA:active {
  opacity: 0.8;
}

.cdAddNewAddressBTNB {
  background-color: #fff;
  border: 2px solid #30373e;
  width: 40%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 1;
  cursor: pointer;
  height: 42px;
}

.cdAddNewAddressBTNB:active {
  opacity: 0.8;
}

.cdAddNewAddressBTN {
  background-color: #fff;
  border: 2px solid #30373e;
  width: 40%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 1;
  cursor: pointer;
}

.cdAddNewAddressBTN:active {
  opacity: 0.8;
}

.cdHeading {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
}

.cdHeadingA {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #000000;
  padding: 0px 0px 16px 0px;
}

.cdAlignAddress {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0px 16px 0px;
}

.cdAlignAddress1 {
  border: 1px solid #EAEAEA;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: flex-start;
}

.cdAddressName {
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 2px 0px;
}
.cdAddressDetails {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  padding: 2px 0px 0px 0px;
}

.cdAddressDetailsNumber {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #717171;
  padding: 2px 0px 0px 0px;
}

.acdAlignOtherBTN {
  display: flex;
  gap: 16px;
}

.acdAlignOtherBTN1 {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #2994ff;
  cursor: pointer;
  opacity: 1;
}

.acdAlignOtherBTN2 {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #ff1329;
  cursor: pointer;
  opacity: 1;
}

.acdAlignOtherBTN1 .acdAlignOtherBTN2:active {
  opacity: 0.8;
}

.cdAlignDiv {
  display: flex;
  gap: 16px;
}

.cdAlignDiv1 {
  width: 49%;
}

.cdAlignDiv2 {
  width: 100%;
}

.cdHead {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.cdInput {
  width: 100%;
  margin: 4px 0px 4px 0px;
  height: 42px;
  border: 1px solid #eaeaea;
}

.cdBtn {
  width: 100%;
  padding: 32px 0px 0px 0px;
}

.cdBtns {
  width: 100%;
  background-color: #30373e;
  color: #ffffff;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  padding: 8px 16px 8px 16px;
}

.cdDivider {
  width: 1%;
}

.cdDivider1 {
  background-color: #e2e2e2;
  height: 100vh;
  width: 1px;
}

.cdContainerOderDiv {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
}

.cdImg {
  width: 24%;
  padding: 16px;
  background-color: #fff8ef;
}

.cdAlignDiv4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cdAlignMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cdKindrea {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 0px 4px;
}

.cdKindreaText {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 4px 0px 0px 4px;
}

.cdprice {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #30373e;
  padding: 0px 0px 0px 0px;
}

.cdAlignDiv3 {
  display: flex;
  align-items: center;
}

.cdRing {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.cdDivider2 {
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
}

.cdPriceDiv {
  display: flex;
  justify-content: space-between;
  width: 92%;
  padding: 0px 0px 0px 24px;
}

.cdPriceHead {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
}

.cdPrices {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.cdPriceHeadM {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
}

.cdPricesHeadM {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.cdNote {
  padding: 16px 0px 0px 0px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .cdContainer {
    padding: 48px 16px 32px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
  }

  .cdContainerDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cdAddNewAddressBTN {
    background-color: #fff;
    border: 2px solid #30373e;
    width: 40%;
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0em;
    text-align: center;
    opacity: 1;
    cursor: pointer;
  }

  .cdAddNewAddressBTN:active {
    opacity: 0.8;
  }

  .cdAddressName {
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 2px 0px;
  }

  .cdAddressDetails {
    font-family: Open Sans;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    padding: 2px 0px 0px 0px;
  }

  .cdAddressDetailsNumber {
    font-family: Open Sans;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #717171;
    padding: 2px 0px 0px 0px;
  }

  .cdHeading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #000000;
  }

  .cdAlignDiv {
    display: flex;
    gap: 16px;
  }

  .cdAlignDiv1 {
    width: 49%;
  }

  .cdAlignDiv2 {
    width: 100%;
  }

  .cdHead {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .cdInput {
    width: 100%;
    margin: 4px 0px 4px 0px;
    height: 42px;
    border: 1px solid #eaeaea;
  }

  .cdBtn {
    width: 100%;
    padding: 0px 0px 0px 0px;
  }

  .cdBtns {
    width: 100%;
    background-color: #30373e;
    color: #ffffff;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    border: none;
    padding: 8px 16px 8px 16px;
  }

  .cdDivider {
    width: 1%;
  }

  .cdDivider1 {
    background-color: #e2e2e2;
    height: 100vh;
    width: 1px;
  }

  .cdContainerOderDiv {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
  }

  .cdImg {
    width: 24%;
    padding: 16px;
    background-color: #fff8ef;
  }

  .cdAlignDiv4 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .cdAlignMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .cdKindrea {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 0px 4px;
  }

  .cdKindreaText {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 4px 0px 0px 4px;
  }

  .cdprice {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #30373e;
    padding: 0px 0px 0px 0px;
  }

  .cdAlignDiv3 {
    display: flex;
    align-items: center;
  }

  .cdRing {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .cdDivider2 {
    width: 100%;
    height: 1px;
    background-color: #eaeaea;
  }

  .cdPriceDiv {
    display: flex;
    justify-content: space-between;
    width: 92%;
    padding: 0px 0px 0px 24px;
  }

  .cdPriceHead {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
  }

  .cdPrices {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .cdPriceHeadM {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
  }

  .cdPricesHeadM {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .cdNote {
    padding: 16px 0px 0px 0px;
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }
}
