.engagement1Container {
  padding: 0px 123px 0px 123px;
  position: relative;
}

.engagement1Container-div1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.engagement1Container-div2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: -84px 0px 0px 0px;
}

.engagement1Img1 {
  width: 52%;
  height: auto;
  z-index: 1;
}
.engagement1Img2 {
  width: 52%;
  height: auto;
  z-index: 0;
}

.alignEnagement1Div1 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 42%;
}

.alignEnagement1Div2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  width: 42%;
}

.engagement1Heading1 {
  padding: 56px 0px 0px 0px;
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.engagement1Heading2 {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 84px 0px 0px 0px;
}

.engagement1txt1 {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #717171;
}

.engagement1txt2 {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #717171;
}

.engagement1btns {
  border: 2px solid #30373e;
  color: #30373e;
  font-family: Alike;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  background-color: #fff;
  width: 80%;
  padding: 8px 0px 8px 0px;
}

.engagement1btns1 {
  border: none;
  color: #ffffff;
  font-family: Alike;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  background-color: #30373e;
  width: 80%;
  padding: 8px 0px 8px 0px;
  margin: 32px 0px 0px 0px;
}

@media screen and (max-width: 1440px) {
  .engagement1Container {
    padding: 0px 123px 0px 123px;
    position: relative;
  }

  .engagement1Container-div1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .engagement1Container-div2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: -84px 0px 0px 0px;
  }

  .engagement1Img1 {
    width: 52%;
    height: auto;
    z-index: 1;
  }
  .engagement1Img2 {
    width: 52%;
    height: auto;
    z-index: 0;
  }

  .alignEnagement1Div1 {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 42%;
  }

  .alignEnagement1Div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    width: 42%;
  }

  .engagement1Heading1 {
    padding: 48px 0px 0px 0px;
    font-family: Alike;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .engagement1Heading2 {
    font-family: Alike;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 84px 0px 0px 0px;
  }

  .engagement1txt1 {
    font-family: Open Sans;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #717171;
  }

  .engagement1txt2 {
    font-family: Open Sans;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #717171;
  }

  .engagement1btns {
    border: 2px solid #30373e;
    color: #30373e;
    font-family: Alike;
    font-size: 1rem;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.025em;
    text-align: center;
    background-color: #fff;
    width: 60%;
    padding: 4px 0px 4px 0px;
  }

  .engagement1btns1 {
    border: none;
    color: #ffffff;
    font-family: Alike;
    font-size: 1rem;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.025em;
    text-align: center;
    background-color: #30373e;
    width: 60%;
    padding: 4px 0px 4px 0px;
    margin: 24px 0px 0px 0px;
  }
}

@media screen and (max-width: 430px) {
  .engagement1Container {
    padding: 0px 16px 0px 16px;
    position: relative;
  }

  .engagement1Container-div1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  .engagement1Container-div2 {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 32px 0px 0px 0px;
  }

  .engagement1Img1 {
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .engagement1Img2 {
    width: 100%;
    height: auto;
    z-index: 0;
  }

  .alignEnagement1Div1 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .alignEnagement1Div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    width: 100%;
  }

  .engagement1Heading1 {
    padding: 0px 0px 0px 0px;
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .engagement1Heading2 {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 16px 0px 0px 0px;
  }

  .engagement1txt1 {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #717171;
  }

  .engagement1txt2 {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #717171;
  }

  .engagement1btns {
    border: 2px solid #30373e;
    color: #30373e;
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.025em;
    text-align: center;
    background-color: #fff;
    width: 100%;
    padding: 4px 0px 4px 0px;
  }

  .engagement1btns1 {
    border: none;
    color: #ffffff;
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.025em;
    text-align: center;
    background-color: #30373e;
    width: 100%;
    padding: 4px 0px 4px 0px;
    margin: 16px 0px 16px 0px;
  }
}
