.engagement2Container {
  height: auto;
  padding: 72px 123px 72px 123px;
}

.engagement2heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0px 0px 32px 0px;
  color: #30373e;
}

.engagement2ImagesDiv {
  width: calc(23%);
  display: flex;
  flex-direction: column;
}

.engagement2Images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: calc(2.5%);
  row-gap: 24px;
}

.engagement2img {
  width: 100%;
}

.engagement2Texts {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

.eg2Div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.eg2Div12 {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: calc(7%);
  width: 40%;
  gap: 32px;
}

.eg2Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto;
}

.eg2Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  width: 70%;
  color: #30373e;
}

.eg2Btn {
  width: 50%;
  background-color: #fff;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  border: none;
  height: 54px;
  opacity: 1;
  cursor: pointer;
}

.eg2Btn:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: #fcfafa;
}

@media screen and (max-width: 430px) {
  .engagement2Container {
    height: auto;
    padding: 32px 16px 32px 16px;
  }

  .engagement2heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    padding: 0px 0px 16px 0px;
    color: #30373e;
  }

  .engagement2Images {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    column-gap: 16px;
    row-gap: 0px;
  }

  .engagement2ImagesDiv {
    width: 48%;
  }

  .engagement2img {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .engagement2Texts {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }

  .eg2Div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .eg2Div12 {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    right: auto;
    width: auto;
    gap: 32px;
    top: 32px;
  }

  .eg2Head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px;
    width: 94%;
  }

  .eg2Text {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.025em;
    text-align: justify;
    width: 90%;
    margin: 0px;
    color: #30373e;
  }
}
