.sd2Container {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 123px 72px 123px;
}

.sd2Head {
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.sd2AlignDiv {
  display: flex;
  gap: 8px;
  padding: 32px 0px 0px 0px;
  width: 100%;
}

.sd2Divs {
  border: 1px double #eaeaea;
  width: calc(15%);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .sd2Container {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 16px 32px 16px;
  }

  .sd2Head {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .sd2AlignDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .sd2Divs {
    border: 1px double #eaeaea;
    width: calc(48%);
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
    padding: 12px 0px 12px 0px;
  }
}
