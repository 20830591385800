.headerDialogContainer {
  display: flex;
  position: fixed;
  z-index: 999;
  background: #fff;
  top: 116px;
  cursor: pointer;
}

.headerDialogContainerDiv {
  width: 60%;
  display: flex;
  gap: 48px;
  padding: 48px 0px 0px 123px;
}

.headerDialogColorDiv1 {
  width: 40%;
}

.headerDialogContainer1 {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.headerDialogHeading {
  font-family: Alike;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 16px 0px;
}

.headerDialogtexts {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.headerDialogAlignDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.headerDialogAlignDivx {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 50vh;
  overflow-y: auto;
  padding: 0px 20px 0px 0px;
}

.headerDialogAlignDiv1 {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0px 0px 8px 0px;
}

.headerDialogColorDiv {
  height: 24px;
  width: 24px;
}

.headerDialogContainerMain {
  display: flex;
  position: fixed;
  justify-content: space-between;
  z-index: 999;
  background: #fff;
  top: 116px;
  width: 100%;
  cursor: pointer;
}

.headerDialogMain {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.headerDialogMainHead {
  font-family: Alike;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 72px 0px 0px 123px;
  cursor: pointer;
}

.headerDialogMainHead1 {
  font-family: Alike;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 16px 0px 0px 123px;
  cursor: pointer;
}
.engaeRingNextIcon{
  margin-left: 20px;
}
.backIconImg{
  margin-left: 20px;
  height: 15px;
  transform: rotate(180deg);
}