.ppp {
  padding: 172px 123px 123px 123px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ppUserName {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
  display: flex;
  gap: 16px;
  align-items: center;
}

.pppContainer {
  background-color: #f9e5e9;
  padding: 32px 16px 16px 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 64px;
  align-items: flex-start;
}
.pppbtncontainer{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.pppHead {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
  padding: 0px 0px 16px 0px;
}

.pppText {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.ppDivider {
  width: 100%;
  border: 1px solid #edbcbc;
  margin: 16px 0px 16px 0px;
}

.pppBg {
  background-color: #f5dbdb80;
  padding: 16px;
  margin: 0px 0px 16px 0px;
}

.pppHead1 {
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
}

.pppText {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 4px 0px 0px 0px;
}
.pppAddress{
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2da0f9;
  padding: 4px 0px 0px 0px;
  cursor: pointer;
}

.ppGender {
  display: flex;
  background-color: #f5dbdb80;
  padding: 16px;
}

.ppGA {
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
  border-color: #edbcbc;
  border-style: solid;
  border-width: 0px 1px 0px 0px;
}

.ppGU {
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
  border-color: #edbcbc;
  border-style: solid;
  border-width: 0px 0px 0px 1px;
}
.logoutBTN {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  background-color: #30373e;
  height: 42px;
  border: none;
  cursor: pointer;
  opacity: 1;
  margin-top: 10px;
}

.logoutBTN:active {
  opacity: 0.8;
}

@media screen and (max-width: 430px) {
  .ppp{
    padding: 80px 0 0;
  }
  .pppContainer{
    flex-direction: column;
    flex-wrap: wrap;
    padding: 50px;
  }
}