.home4Div {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.home43Img1 {
  width: 100%;
  cursor: pointer;
}

.home4Div12 {
  position: absolute;
  width: 50%;
  left: 123px;
  cursor: pointer;
}

.home4Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  cursor: pointer;
  width: 80%;
  margin: 0px;
}

.home4Text {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
  cursor: pointer;
}

.alignHome4Div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  padding: 0px 0px 16px 0px;
  cursor: pointer;
}

.home4Img {
  width: 48px;
  cursor: pointer;
}

.home4Head1 {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  margin: auto;
  cursor: pointer;
}

.home4Text1 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  margin: auto;
  cursor: pointer;
}

@media screen and (min-width: 1350px) and (max-width: 1600px) {
  .home4Div {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .home43Img1 {
    width: 100%;
    cursor: pointer;
  }

  .home4Div12 {
    position: absolute;
    width: 50%;
    left: 123px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .home4Head {
    font-family: Alike;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    cursor: pointer;
    width: 80%;
    margin: 0px;
  }

  .home4Text {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #30373e;
    cursor: pointer;
  }

  .alignHome4Div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
    padding: 0px 0px 16px 0px;
    cursor: pointer;
  }

  .home4Img {
    width: 48px;
    cursor: pointer;
  }

  .home4Head1 {
    font-family: Alike;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;

    cursor: pointer;
  }

  .home4Text1 {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;

    cursor: pointer;
  }
}

@media screen and (max-width: 430px) {
  .home4Div {
    position: relative;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }

  .home43Img1 {
    width: 100%;
    cursor: pointer;
  }

  .home4Div12 {
    position: absolute;
    width: auto;
    top: 32px;
    left: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .home4Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px 16px 0px 0px;
    cursor: pointer;
    width: auto;
  }

  .home4Text {
    font-family: Alike;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.025em;
    text-align: justify;
    color: #30373e;
    cursor: pointer;
    width: 90%;
  }

  .alignHome4Div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    padding: 0px 0px 16px 0px;
    cursor: pointer;
    width: auto;
  }

  .home4Img {
    width: 24px;
    cursor: pointer;
  }

  .home4Head1 {
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    cursor: pointer;
  }

  .home4Text1 {
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: justify;
    color: #30373e;
    width: 90%;
    margin: 0px;
    cursor: pointer;
  }
}
