.home1Container1 {
  height: auto;
  display: flex;
  justify-content: center;
}

.home1Container1-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 72px 123px 0px 123px;
  gap: 16px;
}

.home1Container1-div-div {
  width: calc(30%);
}

.home1ImgDiv1 {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hom1Img {
  width: 90px;
  height: 90px;
}

.home1ImgDiv2 {
  width: 100%;
  height: 140px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
}

.home1Mhead {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.home1Brd {
  font-family: Alike;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.home1CreatedBy {
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.home1Desc {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 90%;
}

@media only screen and (max-width: 430px) {
  .home1Container1 {
    display: flex;
    justify-content: center;
    height: auto;
  }

  .home1Container1-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    padding: 0px 16px 0px 16px;
    gap: 16px;
  }

  .home1Container1-div-div {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home1ImgDiv1 {
    width: 100px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .hom1Img {
    width: 90px;
    height: 90px;
  }

  .home1ImgDiv2 {
    width: 100%;
    height: auto;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }

  .home1Mhead {
    font-family: Alike;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .home1Brd {
    font-family: Alike;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .home1CreatedBy {
    font-family: Open Sans;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .home1Desc {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 70%;
  }
  /* ProductCarousel.css */

  .hom1Div1 {
    width: auto;
  }

  .hom1Div1Img {
    width: 50%;
    margin: auto;
  }
  /* Add more styling as needed */
}
