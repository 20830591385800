.engagement5Container {
  height: auto;
  padding: 72px 123px 72px 123px;
}

.engagement5Heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.engagement5alignDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.engagement5alignDivSize {
  width: calc(33%);
  padding: 0px 0px 0px 0px;
}

.engagement5Img {
  width: 100%;
}

.engagement5Text {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
  padding: 12px 0px 0px 0px;
}

@media screen and (max-width: 430px) {
  .engagement5Container {
    height: auto;
    padding: 32px 16px 32px 16px;
  }

  .engagement5Heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }

  .engagement5alignDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .engagement5alignDivSize {
    width: calc(100%);
    padding: 0px 0px 0px 0px;
  }

  .engagement5Img {
    width: 98%;
    margin: auto;
  }

  .engagement5Text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
    padding: 4px 0px 0px 0px;
  }
}


.image-slider-container {
  overflow: hidden;
}

.image-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 calc(100% / 3); /* Display one and a half images */
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
}

.slide img {
  width: 100%;
  height: auto;
}

.active {
  flex: 0 0 50%; /* Display the current image and a portion of the next one */
}
