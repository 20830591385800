.diamondProductPageContainer {
  height: auto;
  padding: 72px 108px 72px 108px;
  display: flex;
  justify-content: space-between;
}

.diamondProductPageDiv1 {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.diamondProductHeaddd {
  font-family: Alike;
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 72px 0px 0px 123px;
}

.diamondProductImgDiv1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.diamondProductImgDiv2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.diamondProductImg1 {
  width: 100%;
  height: auto;
}

.diamondProductImg2 {
  width: calc(30%);
  background-color: #e9dfd2;
}

.diamondProductPageDiv2 {
  width: 49%;
  padding: 4px 0px 0px 16px;
}

.diamondProductPageDiv2Heading {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 0px 0px;
}

.diamondProductPageDiv2Texts {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 8px 0px 0px 0px;
}

.diamondProductPrice {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  padding: 16px 0px 0px 0px;
}

.diamondProductPageDiv2Texts1 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 16px 0px 32px 0px;
}

.diamondProductPageDiv2Textss2 {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 12px 0px 12px 0px;
}

.diamondProductPageDiv2Heading1 {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 24px 0px 24px 0px;
}

.diamondProductbtn1 {
  width: 100%;
  height: 56px;
  background: #30373e;
  text-align: center;
  color: #fff;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  margin: 12px 0px 0px 0px;
}

.diamondProductbtn2 {
  width: 100%;
  height: 56px;
  text-align: center;
  color: #30373e;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  margin: 12px 0px 0px 0px;
  border: 2px solid #30373e;
}

.diamondProductPageContainerPints {
  padding: 32px 108px 32px 108px;
}

.diamondProductPageContainerPintsC {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.diamondProductCarats1 {
  width: 44%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  background-color: transparent;
  color: #30373e;
  padding: 12px 0px 12px 16px;
}

.diamondProductCarats2 {
  width: 44%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  background-color: #f5ece38a;
  color: #30373e;
  padding: 12px 0px 12px 16px;
}

@media screen and (max-width: 430px) {
  .diamondProductPageContainer {
    height: auto;
    padding: 16px 16px 16px 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .diamondProductPageDiv1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .diamondProductImgDiv1 {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .diamondProductImgDiv2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .diamondProductImg1 {
    width: 100%;
    height: auto;
  }

  .diamondProductImg2 {
    width: calc(30%);
    height: auto;

    background-color: #e9dfd2;
  }

  .diamondProductPageDiv2 {
    width: 100%;
    padding: 8px 0px 0px 0px;
  }

  .diamondProductPageDiv2Heading {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 16px 0px 0px 0px;
  }

  .diamondProductPageDiv2Texts {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 8px 32px 8px 0px;
  }

  .diamondProductPageDiv2Textss2 {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .diamondProductPrice {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    padding: 8px 0px 0px 0px;
  }

  .diamondProductPageDiv2Texts1 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 4px 0px 0px 0px;
  }

  .diamondProductbtn {
    width: 100%;
    height: 56px;
    background: #30373e;
    text-align: center;
    color: #fff;
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: center;
    margin: 12px 0px 0px 0px;
  }

  .diamondProductPageContainerPints {
    padding: 32px 16px 32px 16px;
  }

  .diamondProductPageContainerPintsC {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .diamondProductCarats1 {
    width: 100%;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;
    background-color: transparent;
    color: #30373e;
    padding: 12px 0px 12px 16px;
  }

  .diamondProductCarats2 {
    width: 100%;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;
    background-color: #f5ece38a;
    color: #30373e;
    padding: 12px 0px 12px 16px;
  }

  
.diamondProductHeaddd {
  font-family: Alike;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0px 0px 0px 16px;
}

}
