.home6Container {
  height: auto;
  overflow: hidden;
}

.hom6Div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hom6Div1 {
  position: absolute;
  left: 123px;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  top: calc(24%);
}
.hom6Div1last{
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  gap: 16px;
  width: 44%;
  top: calc(24%);
}
.home6Headlast{
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}
.hom6Divlast{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.home6Textlast{
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 80%;
}
.hom6Btnlast{
  width: 50%;
  border: none;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: left;
  display: flex;
  cursor: pointer;
  color: #875E4A;
}

.home6Head {
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.home6Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  width: 80%;
}

.hom6Btn {
  width: 50%;
  background-color: #fff;
  border: none;
  height: 42px;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hom6Btn:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: #fcfafa;
}

.home6Img {
  width: 100%;
}

@media screen and (max-width: 430px) {
  .home6Container {
    height: auto;
  }

  .hom6Div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hom6Div1 {
    position: absolute;
    left: auto;
    top: auto;
    bottom: calc(10%);
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  .hom6Divm{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .hom6Div1m{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: auto;
    top: auto;
    left: auto;
    top: calc(18%);
  }
  .home6Headm{
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px;
  }
  .home6Textm{
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: justify;    
    width: 84%;
    margin: 0px;
  }
  .hom6Btnm{
    width: 50%;
    background-color: #fff;
    font-family: Alike;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    border: none;
    height: 2rem;
    opacity: 1;
    cursor: pointer;
  }

  .home6Head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px;
  }

  .home6Text {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: justify;    
    width: 84%;
    margin: 0px;
  }

  .hom6Btn {
    width: 50%;
    background-color: #fff;
    font-family: Alike;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    border: none;
    height: 2rem;
    opacity: 1;
    cursor: pointer;
  }

  .hom6Btn:hover {
    opacity: 0.8;
    cursor: pointer;
    background-color: #fcfafa;
  }

  .home6Img {
    width: 100%;
  }
}
