.bg3Container {
  padding: 72px 123px 72px 123px;
}

.bg3Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

@media screen and (max-width: 430px) {
  .bg3Container {
    padding: 0px 16px 0px 16px;
  }

  .bg3Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 8px 0px;
  }
}
