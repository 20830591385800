.headerContainer {
  background-color: #f8f6f4cc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 32px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.mainLogo {
  position: absolute;
  left: 123px;
  width: 10%;
}

.headerContainer-div {
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.headerContainer-div-div {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  color: #30373e;
  opacity: 1;
  cursor: pointer;
}

.headerContainer-div-divA {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.025em;
  color: #30373e;
  opacity: 1;
  cursor: pointer;
}

.headerContainer-div-div:active {
  opacity: 0.8;
}

.menuIconSize {
  width: 20px;
  height: 20px;
  margin: 0px 16px 0px 16px;
}

.headerContainerM {
  background-color: #f8f6f4cc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 32px;
  transition: background-color 0.3s ease;
}

/* src/Drawer.css */
.drawer {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 16px;
  z-index: 999;
}

.drawer.open {
  width: 70%;
}

.drawer-content {
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.drawer-align {
  display: flex;
  justify-content: space-between;
  opacity: 1;
  margin: 8px 0px 4px 0px;
}

.drawer ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.drawer li:hover {
  background-color: #555;
}

.headerAlignIcons {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 0px 0px 0px 0px;
  position: absolute;
  right: calc(10%);
}

.userIcon {
  opacity: 1;
  cursor: pointer;
}

.userIcon:active {
  opacity: 0.8;
}

@media screen and (max-width: 430px) {
  .headerContainer-div-divA {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.025em;
    color: #30373e;
    opacity: 1;
    cursor: pointer;
  }

  .headerContainer-div-div {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.025em;
    color: #30373e;
    opacity: 1;
    cursor: pointer;
  }
}
