.gp2Container {
  padding: 72px 123px 72px 123px;
}

.gp2AlignData {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.gp2Div {
  width: calc(10%);
  text-align: center;
}

.gp2Img {
  width: 48px;
}

.gp2Text {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
  padding: 4px 0px 0px 0px;
}

.gp2Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 48px 0px;
}

@media screen and (max-width: 430px) {
  .gp2Container {
    padding: 16px 16px 32px 16px;
  }

  .gp2AlignData {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 16px;
  }

  .gp2Div {
    width: calc(30%);
    text-align: center;
  }

  .gp2Img {
    width: 16px;
  }

  .gp2Text {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
    padding: 4px 0px 0px 0px;
  }

  .gp2Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }
}
