.dp2Container {
  height: auto;
  padding: 72px 123px 0px 123px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.dp2Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 60%;
}

.dp2text {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #717171;
  width: 60%;
  color: #30373e;
}

.dp2Container1 {
  height: auto;
  padding: 64px 123px 0px 123px;
}

.alignDp2Data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.dp2DataDiv {
  width: calc(24%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.dp2Img {
  width: 72px;
}

.dp2DataHead {
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.dp2Desc {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.015em;
  text-align: center;
  color: #717171;
  width: 90%;
}

@media screen and (max-width: 430px) {
  .dp2Container {
    height: auto;
    padding: 72px 16px 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .dp2Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 100%;
  }

  .dp2text {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #717171;
    width: 80%;
  }

  .dp2Container1 {
    height: auto;
    padding: 32px 16px 0px 16px;
  }

  .alignDp2Data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .dp2DataDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0px 0px 0px;
  }

  .dp2Img {
    width: 72px;
    margin: auto;
  }

  .dp2DataHead {
    font-family: Alike;
    font-size: 1rem;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: auto;
    padding: 16px 0px 16px 0px;
  }

  .dp2Desc {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.015em;
    text-align: center;
    color: #717171;
    width: 84%;
    margin: auto;
    word-spacing: 1px;
  }
}
