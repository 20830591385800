.wrDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.wrDiv12 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  right: calc(7%);
  width: 40%;
  gap: 32px;
}

.wrEDiv12 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  left: 123px;
  width: 40%;
  gap: 32px;
}

.wrHead {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.2rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto;
}

.wrText {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  width: 100%;
}

.wrBtn {
  width: 60%;
  background-color: #fff;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  border: none;
  height: 54px;
  opacity: 1;
  cursor: pointer;
}

.wrBtn:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: #fcfafa;
}

@media screen and (max-width: 430px) {
  .wrDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .wrDiv12 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    align-items: center;
    right: 0;
    top: 32px;
    left: auto;
    width: auto;
    gap: 16px;
  }

  .wrHead {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 90%;
  }

  .wrText {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.025em;
    text-align: center;
    width: 90%;
  }

  .wrBtn {
    width: 60%;
    background-color: #fff;
    font-family: Alike;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    border: none;
    height: 42px;
    opacity: 1;
    cursor: pointer;
  }

  .wrBtn:hover {
    opacity: 0.8;
    cursor: pointer;
    background-color: #fcfafa;
  }
}
