.dn3Container {
  padding: 72px 123px 72px 123px;
}

.dn3head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.dn3Container1 {
  margin: 0px 123px 72px 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}

.dna3Head {
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.dna3Headabc {
  display: flex;
  align-items: center;
  gap: 32px;
}

table,
th,
tr,
td {
  border: 1px solid #d9d9d9;
  border-collapse: collapse;
}

.dn3Align {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.dn3td {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: grey;
}

.dn3Headdd {
  font-family: Alike;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 0px 0px 0px 8px;
}

.dn3Span {
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 8px;
}

.dn3Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dn3Btn1 {
  width: 30%;
  background-color: #30373e;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  border: none;
  height: 42px;
}

.alignD3ImgData {
  display: flex;
  justify-content: space-between;
}

.dn3Imgs {
  width: 12%;
}

@media screen and (max-width: 430px) {
  .dn3Container {
    padding: 32px 16px 32px 16px;
  }

  .dn3head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }

  .dn3Imgs {
    width: 24px;
  }

  .dn3Container1 {
    margin: 0px 16px 32px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 32px;
  }

  .dna3Head {
    font-family: Alike;
    font-size: 1rem;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .dn3Btn1 {
    width: 80%;
    background-color: #30373e;
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border: none;
    height: 32px;
  }

  .dn3TableContainerMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
  }

  .dn3TableContainer {
    width: 80%;
    display: flex;
    justify-content: center;
    gap: calc(54%);
    font-family: Alike;
    font-size: 20px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .dn3TableContainer1 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;

    color: #30373e;
  }

  .dn3TableContainerDiv {
    width: 80px;
    text-align: center;
  }
  .dn3TableContainer2 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5ece38a;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.025em;
    text-align: left;
    background-color: #f5ece38a;
    color: #30373e;
  }
}
