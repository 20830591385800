.wr2Container {
  height: auto;
  padding: 0px 123px 72px 123px;
}

.wr2Div {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0px;
  padding: 0px 0px 0px 0px;
}

.wr2Divs {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.wr2head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 80%;
}

.wr2texts {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 80%;
}

.wr2Img {
  width: 100%;
}

.wr2Btn {
  width: 100%;
  background-color: #fff;
  font-family: Alike;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  border: none;
  padding: 8px 0px 8px 0px;
}

@media screen and (max-width: 430px) {
  .wr2Container {
    height: auto;
    padding: 0px 16px 32px 16px;
  }

  .wr2Div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0px 16px 0px;
  }

  .wr2Divs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .wr2head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 100%;
  }

  .wr2texts {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 100%;
  }

  .wr2Img {
    width: 100%;
  }

  .wr2Btn {
    width: 100%;
    border: 1px solid #30373e;
    font-family: Alike;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 2px 0px 2px 0px;
  }
}
