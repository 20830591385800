.selectSetting2Container1 {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 72px 123px 0px 123px;
}

.selectSetting2Container1Sub {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selectSetting2BoxesA {
  display: flex;
  gap: 32px;
  background-color: #30373e;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32%;
  padding: 8px 0px 8px 0px;
}

.selectSetting2BoxesU {
  display: flex;
  gap: 32px;
  border: 1px solid #30373e;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32%;
  padding: 8px 0px 8px 0px;
}

.selectSetting2NumA {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 4px 16px 4px 16px;
}

.selectSetting2NumU {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  border: 1px solid #30373e;
  border-radius: 100%;
  padding: 4px 16px 4px 16px;
}

.selectSetting2TextA {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #fff;
}

.selectSetting2TextU {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .selectSetting2Container1 {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px 32px 16px;
  }

  .selectSetting2Container1Sub {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    align-items: center;
  }

  .selectSetting2BoxesA {
    background-color: #30373e;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    padding: 8px 16px 8px 16px;
  }

  .selectSetting2BoxesU {
    border: 1px solid #30373e;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    padding: 8px 16px 8px 16px;
  }

  .selectSetting2NumA {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 100%;
    padding: 4px 10px 4px 10px;
  }

  .selectSetting2NumU {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    border: 1px solid #30373e;
    border-radius: 100%;
    padding: 4px 10px 4px 10px;
  }

  .selectSetting2TextA {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #fff;
  }

  .selectSetting2TextU {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }
}
