.rings1Container {
  padding: 72px 123px 72px 123px;
}

.rings1Heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.alignRings1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  row-gap: 32px;
}

.ring1Div {
  width: calc(24%);
}

.ring1Img {
  width: 100%;
}

.rings1ImageName {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

.rings1ContainerAb {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.rings1ContainerAb1 {
  position: absolute;
  width: 40%;
  right: 72px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.rings1ContainerAb1Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.rings1ContainerAb1Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
  width: 70%;
}

@media screen and (max-width: 430px) {
  .rings1Container {
    padding: 32px 16px 32px 16px;
  }

  .rings1Heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 32px 0px;
  }

  .alignRings1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    row-gap: 32px;
  }

  .ring1Div {
    width: calc(48%);
  }

  .ring1Img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .rings1ImageName {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }

  .rings1ContainerAb {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }

  .rings1ContainerAb1 {
    position: absolute;
    width: 100%;
    right: auto;
    top: 16px;
    left: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .rings1ContainerAb1Head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .rings1ContainerAb1Text {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015em;
    text-align: center;
    color: #30373e;
    width: 84%;
  }
}
