.gp1Div {
  position: relative;
  display: flex;
  align-items: center;
}

.gp1Div12 {
  width: 30%;
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 16px;
  left: 123px;
}

.gp1Head {
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.gp1Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
}

.gp1Btn {
  width: 70%;
  background-color: #30373e;
  border: none;
  height: 42px;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  color: #f8fafd;
}

.gp1Btn:hover {
  opacity: 0.9;
  background-color: #f8fafd;
}

.gp1Img {
  width: 100%;
}

@media screen and (max-width: 430px) {
  .gp1Div {
    position: relative;
    display: flex;
    align-items: center;
  }

  .gp1Div12 {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 16px;
    left: auto;
    top: calc(20%);
    align-items: center;
  }
  .gp1Img {
    width: 100%;
  }

  .gp1Head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    /* margin: auto 0px auto 0px; */
  }

  .gp1Text {
    font-family: Open Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: 0.015em;
    text-align: center;
    color: #30373e;
    margin: 0px 0px 0px 0px;
    width: 84%;
  }

  .gp1Btn {
    width: 60%;
    background-color: #30373e;
    border: none;
    height: 42px;
    font-family: Alike;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    color: #f8fafd;
  }

  .gp1Btn:hover {
    opacity: 0.9;
    background-color: #f8fafd;
  }
}
