.nlContainer1 {
  height: auto;
  position: relative;
}

.nlMainImg {
  width: 100%;
}

.nlContainer1AlignDiv {
  position: absolute;
  bottom: calc(10%);
  left: calc(64%);
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.nlMainHead {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.nlMainText {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 80%;
}

.nlBtn1 {
  border: none;
  background-color: #fff;
  color: #30373e;
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 4px 96px 4px 96px;
}

.nlBtn2 {
  border: none;
  background-color: #30373e;
  color: #fff;
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 4px 60px 4px 60px;
}

.nlContainer2 {
  padding: 72px 123px 72px 123px;
}

.nl2MainHead {
  font-family: Alike;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.nl2AlignData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nl2Div {
  width: calc(24%);
  text-align: center;
}

.nl2Img {
  width: 100%;
}

.nl2Text {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .nlContainer2 {
    padding: 32px 16px 32px 16px;
  }

  .nl2MainHead {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }

  .nl2AlignData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 16px;
  }

  .nl2Div {
    width: calc(48%);
    text-align: center;
  }

  .nl2Img {
    width: 90%;
    margin: auto;
  }

  .nl2Text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }
}
