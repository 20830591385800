.ssGap {
  padding: 16px;
}

.alignSS2Data {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: calc(1.33%);
  row-gap: 32px;
  padding: 72px 123px 72px 123px;
}

.ssFilter {
  width: 100%;
  height: 56px;
  background-color: #30373e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.025em;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  z-index: 999;
}

.ssFilter:active {
  opacity: 0.8;
}

.filterDrawer {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 600px; /* Set the height as per your requirement */
  background-color: #fff;
  transition: bottom 0.3s ease-out;
  border-radius: 16px 16px 0px 0px;
  z-index: 999;
  /* box-shadow: 0 -5px 15px #30373e; */
}

.filterDrawer.open {
  bottom: 0;
  overflow-y: auto;
}

.ssAlignFilter {
  display: flex;
  justify-content: center;
  padding: 16px 0px 0px 0px;
  opacity: 1;
}

.ssFilterHeading {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.ssFilterClose {
  position: absolute;
  right: 16px;
}

.ssFilterClose:active {
  opacity: 0.8;
}
@media screen and (min-width: 1100px) and (max-width: 1440px) {
  .ssGap {
    padding: 64px 0px 0px 0px;
  }
}

@media screen and (min-width: 1441px) {
  .ssGap {
    padding: 116px 0px 0px 0px;
  }
}

@media screen and (max-width: 430px) {
  .ssGap {
    padding: 64px 0px 0px 0px;
  }

  .alignSS2Data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
    padding: 32px 16px 64px 16px;
  }
}
