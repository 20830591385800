.sspCon {
  width: calc(24%);
  cursor: pointer;
}

.sspImge {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 430px) {
  .sspCon {
    width: calc(48%);
  }

  .sspImge {
    width: 100%;
    height: auto;
  }
}

.selectSettingViewContainer {
  height: auto;
  padding: 72px 123px 72px 123px;
}

.selectSettingViewAlignProducts {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 32px 123px 96px 123px;
  row-gap: 24px;
  gap: calc(4%);
}

.selectSettingViewProductBox {
  width: calc(22%);
}

.selectSettingViewImgSize {
  width: 100%;
  background: #fff;
}

.selectSettingViewFont1 {
  font-family: Alike;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.selectSettingViewFont2 {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #717171;
}

.selectSettingViewPrice {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .selectSettingViewContainer {
    height: auto;
    padding: 32px 16px 32px 16px;
  }

  .selectSettingViewAlignProducts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 32px 16px 32px 16px;
    row-gap: 16px;
    column-gap: 16px;
  }

  .selectSettingViewProductBox {
    width: calc(26%);
  }

  .selectSettingViewImgSize {
    width: 100%;
    background: #fff;
  }

  .selectSettingViewFont1 {
    font-family: Alike;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .selectSettingViewFont2 {
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 400;
    line-height: 9px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #717171;
    padding: 4px 0px 0px 0px;
  }

  .selectSettingViewPrice {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    padding: 4px 0px 0px 0px;
  }
}
