.hdrawer {
  padding: 16px 8px 0px 8px;
}

.hdrawer1 {
  padding: 16px 8px 0px 8px;
}

.headerDrawerAlignDiv {
  display: flex;
  flex-direction: column;
  padding: 4px 0px 0px 0px;
}

.headerDHeading {
  font-family: Alike;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 8px 0px;
  text-transform: uppercase;
}

.headerDText {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.headerDAlignDivx {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 20vh;
  overflow-y: auto;
  padding: 0px 20px 0px 0px;
}

.headerDText1 {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0px 0px 8px;
  opacity: 1;
  cursor: pointer;
}

.headerDText1:active {
  opacity: 0.8;
}

.headerDHeading1 {
  font-family: Alike;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  cursor: pointer;
  text-transform: uppercase;
}
.headerAlignIconsHD{
  display: flex;
  justify-content: center;
  gap: 10vw;
  margin-top: 10px;
}