.diamondCategoryContainer {
  height: auto;
  padding: 32px 108px 72px 108px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
}

.diamondCategoryPoducts {
  width: calc(24%);
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.diamondCategoryPoductImg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.diamondCategoryAlignDivs {
  display: flex;
  justify-content: space-between;
  padding: 4px 8px 4px 8px;
}

.diamondCategoryAlignDivsData {
  display: flex;
  justify-content: space-between;
  width: 44%;
}

.diamondCategoryhead {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #717171;
  width: 56px;
  padding: 0px 8px 0px 0px;
}

.diamondCategoryvalues {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  width: 72px;
}

.diamondCategoryAlignDivs1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 0px 4px 0px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.diamondCategoryBtn {
  width: 100%;
  height: 48px;
  background-color: #30373e;
  border: none;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 430px) {
  .diamondCategoryContainer {
    height: auto;
    padding: 16px 16px 32px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
  }

  .diamondCategoryPoducts {
    width: calc(48%);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .diamondCategoryPoductImg {
    width: 100%;
    height: 180px;
    object-fit: cover;
    margin-bottom: 2px;
  }

  .diamondCategoryAlignDivs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2px;
    padding: 0px 4px 2px 4px;
  }

  .diamondCategoryAlignDivsData {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .diamondCategoryhead {
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 400;
    line-height: 8px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #717171;
    width: 100%;
    padding: 0px 0px 0px 0px;
  }

  .diamondCategoryvalues {
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 700;
    line-height: 8px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    width: auto;
  }

  .diamondCategoryAlignDivs1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0px 4px 0px;
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 700;
    line-height: 8px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
  }

  .diamondCategoryBtn {
    width: 100%;
    height: 24px;
    background-color: #30373e;
    border: none;
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
  }
}
