.wr9Container {
  padding: 72px 123px 72px 123px;
}

.wr9heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}
.wr9AlignRingData {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 32px 0px 0px 0px;
}

.wr9RingDivSize {
  width: calc(22%);
}

.wr9Img {
  width: 100%;
  height: auto;
}

.wr9RingName {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .wr9Container {
    padding: 32px 16px 32px 16px;
  }

  .wr9heading {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    text-transform: capitalize;
  }
  .wr9AlignRingData {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px 0px 0px 0px;
  }

  .wr9RingDivSize {
    width: calc(20%);
  }

  .wr9Img {
    width: 100%;
    height: auto;
  }

  .wr9RingName {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }
}
