.loginContainerR {
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.loginContainerDivR {
    position: absolute;
    right: 123px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.loginHeadR {
    font-family: Alike;
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.loginHeadsR {
    color: #30373e;
    font-family: Alike;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.025em;
    text-align: left;
}

.registerInputR {
    width: 100%;
    margin: 16px 0px 0px 0px;
    border: none;
    height: 42px;
    width: 100%;
}

.aliggnTBRR {
    display: flex;
    gap: 16px;
    align-items: flex-end;
}

.registerBTNR:active {
    opacity: 0.8;
}
.registerBTNR{
    font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  background-color: #30373e;
  height: 42px;
  border: none;
  cursor: pointer;
  opacity: 1;
}

.registerPrivacyTextR{
    font-family: Alike;
    font-size: 0.8rem;
    text-align: center;
    color: #00000099;
    margin-top: 10px;
}
@media screen and (max-width: 430px) {
    .loginHeadR{
      font-size: 24px;
      line-height: 30px;
    }
    .loginHeadsR{
      font-size: 16px;
      line-height: 20px;
    }
    .loginContainerDivR {
      position: absolute;
      right: 0;
      left: 0;
      width: 85%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      margin-left: 30px;
    }
  }