.wr7Container {
  height: auto;
  display: flex;
  justify-content: center;
  padding: 32px 123px 32px 123px;
  background-color: #f9e5e9;
}

.wr7AlignData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.wr7Div {
  width: calc(30%);
}

.wr7ImgDiv {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wr7Img {
  width: 90px;
  height: 90px;
}

.wr7Div2 {
  width: 100%;
  height: 140px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
}

.wr7Head {
  font-family: Alike;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.wr7Brd {
  font-family: Alike;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.wr7CreatedBy {
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.wr7Desc {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 90%;
}

@media only screen and (max-width: 430px) {
  .wr7Container {
    display: flex;
    justify-content: center;
    height: auto;
    padding: 0px 16px 32px 16px;
    background-color: #f9e5e9;
  }

  .wr7AlignData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  .wr7Div {
    width: calc(100%);
  }

  .wr7ImgDiv {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wr7Img {
    width: 90px;
    height: 90px;
  }

  .wr7Div2 {
    width: 100%;
    height: auto;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }

  .wr7Head {
    font-family: Alike;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .wr7Brd {
    font-family: Alike;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .wr7CreatedBy {
    font-family: Open Sans;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .wr7Desc {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 70%;
  }
}
