.mad {
  padding: 48px 123px 28px 123px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.madHead {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
}

.madBtn {
  background-color: #fff;
  width: 20%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  height: 42px;
  color: #30373e;
  border: 2px solid #30373e;
  cursor: pointer;
  opacity: 1;
}

.madBtn:active {
  opacity: 0.8;
}

.madAlign {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 32px;
  padding: 0px 123px 28px 123px;
}

.madBox {
  width: calc(47%);
  padding: 8px;
  border: 1px solid #30373e;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.madAlignBtn {
  display: flex;
  gap: 4px;
}

.madBtn1 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2994ff;
  background-color: #fff;
  border: none;
  cursor: pointer;
  opacity: 1;
}

.madBtn1:active {
  opacity: 0.8;
}

.madBtn2:active {
  opacity: 0.8;
}

.madBtn2 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff1329;
  background-color: #fff;
  border: none;
  cursor: pointer;
  opacity: 1;
}
@media screen and (max-width: 430px) {
  .mad{
    padding: 10px;
  }
  .madBtn{
    width: 30%;
  }
  .madAlign{
    padding: 0 10px;
  }
  .madBox{
    width: 100%;
  }
}