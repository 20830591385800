.thnkContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 32px;
  padding: 64px 0px 0px 0px;
}

.thnk1 {
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.thnk2 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #30373e;
}

.thnk3 {
  font-family: Open Sans Hebrew;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #717171;
}

.thnkBtn {
  width: 40%;
  padding: 16px 0px 16px 0px;
  font-family: Alike;
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  background-color: #30373e;
}
@media only screen and (max-width: 430px) {
  .thnk1{
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  padding: 0 5px;
  }
  .thnk2{
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 0 10px;
  }
  .thnkBtn{
    width: 60%;
  font-size: 22px;
  line-height: 20px;
  }
}