.home9Container {
  display: flex;
  justify-content: space-between;
  padding: 72px 123px 0px 123px;
}

.home9Container-div {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home9Container-heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 70%;
}

.home9Container-sub {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
  width: 80%;
}

.home9Img {
  width: 49%;
}

.home9btn {
  width: 70%;
  height: 42px;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #30373e;
  padding: 0px 0px 0px 0px;
  border: 2px solid #30373e;
}

@media screen and (min-width: 1440px) {
  .home9Container-heading {
    font-family: Alike;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 80%;
  }

  .home9Container-sub {
    font-family: Open Sans;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.015em;
    text-align: left;
    color: #30373e;
    width: 80%;
  }
}

@media only screen and (max-width: 430px) {
  .home9Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 32px 16px 0px 16px;
    gap: 16px;
  }

  .home9Container-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .home9Container-heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 100%;
  }

  .home9Container-sub {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015em;
    text-align: center;
    color: #30373e;
    width: auto;
  }

  .home9Img {
    width: 100%;
  }

  .home9btn {
    width: 30%;
    padding: 0px 16px 0px 16px;
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.025em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #30373e;
    height: 32px;
  }
}
