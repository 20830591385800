.home10Container {
  height: auto;
  padding: 32px 108px 32px 108px;
  background-color: #F9E5E9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home10Container-div1 {
  display: flex;
  width: 48%;
  flex-direction: column;
  justify-content: flex-start;
}

.home10Containertext1 {
  font-family: Alike;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.home10Containertext2 {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #717171;
}

.home10Containers-div2 {
  display: flex;
  width: 48%;
  gap: 16px;
  justify-content: flex-start;
}

.home10Container-input {
  background-color: #fff;
  width: 50%;
  height: 52px;
  border: none;
  font-size: 16px;
  padding: 0px 32px 0px 16px;
}

.home10Btn {
  width: 30%;
  height: 52px;
  background-color: #30373e;
  color: #fff;
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
}
.emsSubsText{
  color: rgb(57, 157, 57);
  font-size: 12;
  font-family: Alike;
  font-size: 500;
}

@media screen and (max-width: 430px) {
  .home10Container {
    height: auto;
    padding: 32px 16px 32px 16px;
    background-color: #F9E5E9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .home10Container-div1 {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .home10Containertext1 {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .home10Containertext2 {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #717171;
  }

  .home10Containers-div2 {
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: center;
  }

  .home10Container-input {
    background-color: #fff;
    width: 50%;
    height: 32px;
    border: none;
    font-size: 12px;
    padding: 0px 0px 0px 16px;
  }

  .home10Btn {
    width: 30%;
    height: 32px;
    background-color: #30373e;
    color: #fff;
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    border: none;
  }
}
