body {
  margin: 0;
  font-family: "Alike", "Open Sans", "Tahoma", "Verdana",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Ubuntu", "Helvetica Neue",
    "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Open Sans", monospace, "Alike";
}
