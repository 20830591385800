.gp3Container {
  height: auto;
  padding: 123px 123px 48px 123px;
  display: flex;
}

.gp3Img {
  width: 100%;
}



@media screen and (max-width: 430px) {
  .gp3Container {
    height: auto;
    padding: 32px 32px 0px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .gp3Img {
    width: 100%;
  }
}
