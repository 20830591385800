.dn2Container {
  padding: 0px 123px 72px 123px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dn2Mhead {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.dn2Mtext {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 50%;
  padding: 0px 0px 32px 0px;
}

.dn2AlignData {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 16px;
  padding: 32px 0px 0px 0px;
}

.dn2Divs {
  width: calc(30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.dn2Img {
  width: 32%;
  height: auto;
  border-radius: 100%;
}

.dn2head {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.dn2Text {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .dn2Container {
    padding: 0px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dn2Mhead {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }

  .dn2Mtext {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 0px 0px 32px 0px;
  }

  .dn2AlignData {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .dn2Divs {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .dn2Img {
    width: 32%;
    height: auto;
    border-radius: 100%;
  }

  .dn2head {
    font-family: Alike;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .dn2Text {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }
}
