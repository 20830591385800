.fineDialogContainer {
  display: flex;
  position: fixed;
  z-index: 999;
  background: #fff;
  top: 116px;
}

.fineDialogContainer1 {
  width: 50%;
  padding: 48px 0px 0px 123px;
  display: flex;
  gap: 72px;
}

.fineDialogAlignDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fineDialogAlignDiv1 {
  display: flex;
  gap: 16px;
  align-items: center;
}
.fineDialogheading {
  font-family: Alike;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
}
.fineDialogContainer2 {
  width: 50%;
}

.fineDContainer {
  display: flex;
  position: fixed;
  z-index: 999;
  background: #fff;
  top: 116px;
  justify-content: space-between;
  width: 100%;
}

.fineDiv1 {
  width: 40%;
  font-family: Alike;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
}
.finHead1 {
  padding: 72px 0px 0px 123px;
  cursor: pointer;
}
.finHead2 {
  padding: 16px 0px 0px 123px;
  cursor: pointer;
}
.finHead3 {
  padding: 16px 0px 0px 123px;
  cursor: pointer;
}
.finHead4 {
  padding: 16px 0px 0px 123px;
  cursor: pointer;
}

.fineDiv2 {
  width: 60%;
}
