.bg2Container {
  height: auto;
  padding: 72px 123px 0px 123px;
}

.bg2Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  padding: 0px 0px 72px 0px;
}

.bg2Container1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bg2Container-div {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0px 0px 0px;
}

.bg2MHead {
  font-family: Alike;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.bg2MText {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 80%;
}

.bg2Img {
  width: 100%;
}

@media screen and (max-width: 430px) {
  .bg2Container {
    height: auto;
    padding: 32px 16px 32px 16px;
  }

  .bg2Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 0px 0px 0px 0px;
  }

  .bg2Container1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .bg2Container-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0px 0px 0px;
  }

  .bg2MHead {
    font-family: Alike;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .bg2MText {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 80%;
  }

  .bg2Img {
    width: 100%;
  }
}
