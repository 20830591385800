.dp1Container {
  height: auto;
}

.dp1Div {
  position: relative;
  display: flex;
}

.dp1Div1 {
  position: absolute;
  left: 123px;
  top: calc(28%);
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dp1Head {
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.dp1Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  width: 80%;
  color: #30373e;
}

.dp1Btn {
  width: 50%;
  background-color: #ebe6e6;
  border: none;
  height: 42px;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dp1Btn:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: #fcfafa;
}

.dp1Img {
  width: 100%;
}

@media screen and (max-width: 430px) {
  .dp1Container {
    height: auto;
  }

  .dp1Div {
    position: relative;
    display: flex;
  }

  .dp1Div1 {
    position: absolute;
    left: auto;
    top: calc(24%);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .dp1Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px;
    width: 94%;
  }

  .dp1Text {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: justify;
    color: #30373e;
    width: 84%;
  }

  .dp1Btn {
    width: 50%;
    background-color: #fff;
    font-family: Alike;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    border: none;
    height: 42px;
    opacity: 1;
    cursor: pointer;
  }

  .dp1Btn:hover {
    opacity: 0.8;
    cursor: pointer;
    background-color: #fcfafa;
  }

  .dp1Img {
    width: 100%;
  }
}
