.engagement4Container {
  height: auto;
  padding: 72px 123px 72px 123px;
}

.engagement4Heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.engagement4alignDiv {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 16px;
  width: 100%;
}

.engagement4Img {
  width: 100%;
}

.eng4ImgDiv {
  width: calc(100%);
}

.engagement4Text {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

.eg3Div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.eg3Div12 {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 123px;
  width: 40%;
  gap: 32px;
}

.eg3Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.eg3Text {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  width: 80%;
  color: #30373e;
}

.eg3Btn {
  width: 40%;
  background-color: #fff;
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  border: none;
  height: 54px;
  opacity: 1;
  cursor: pointer;
}

.eg3Btn:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: #fcfafa;
}

@media screen and (max-width: 430px) {
  .engagement4Container {
    height: auto;
    padding: 32px 16px 32px 16px;
  }

  .engagement4Heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }

  .engagement4alignDiv {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    row-gap: 8px;
    column-gap: 0px;
  }

  .eng4ImgDiv {
    width: calc(100%);
  }

  .engagement4Img {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .engagement4Text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }

  .eg3Div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .eg3Div12 {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    width: auto;
    left: auto;
    gap: 32px;
    top: 32px;
  }

  .eg3Head {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    margin: 0px;
    width: 90%;
  }

  .eg3Text {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.025em;
    text-align: justify;
    width: 84%;
    margin: 0px;
    color: #30373e;
  }

  .eg3Btn {
    width: 40%;
    background-color: #fff;
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    border: none;
    height: 54px;
    opacity: 1;
    cursor: pointer;
  }

  .eg3Btn:hover {
    opacity: 0.8;
    cursor: pointer;
    background-color: #fcfafa;
  }
}
