.shoppingBagContainer {
  height: auto;
  padding: 148px 108px 32px 108px;
}
.shoppingBagheading {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #000000;
  padding: 0px 0px 32px 0px;
}

.shoppingBagMainDiv {
  width: 100%;
  display: flex;
  gap: 32px;
}

.shoppingBagDiv1 {
  width: 56%;
}

.shoppingBagDiv2 {
  width: 40%;
}

.shoppingCrdM {
  display: flex;
}
.shoppingCrdM1 {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.shoppingCrdM2{
  display: flex;
  gap: 16px;
  /* justify-content: space-between; */
  /* background-color: #000000; */
}

.shoppingBagalign {
  display: flex;
  gap: 16px;
  margin: 0px 0px 16px 0px;
}

.shoppingBagImag {
  padding: 16px;
  background-color: #e9dfd2;
  width: 64px;
  height: 64px;
}

.shoppingBagImag1 {
  height: 20px;
  width: 20px;
  margin: 0px 16px 0px 0px;
}
.shoppingBagImag2{
  height: 20px;
  width: 20px;
  margin-left: -36px;
}

.shoppingBagalignDivs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px 0px 16px 0px;
  width: 100%;
}

.shoppingBagPName {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 260px;
}

.shoppingBagPDs {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  text-transform: capitalize;
}
.shoppingBagPDsDia{
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  text-transform: capitalize;
  margin-top: -32px;
}

.shoppingBagPrice {
  font-family: Open Sans;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}
.shoppingBagPriceDia{
  font-family: Open Sans;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
  /* padding-right: 150px; */
  /* background-color: #e9dfd2; */
  position: absolute;
  width: 50vw;
  right: 0;
}

.shoppingBagTotal {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.shoppingBagalignPrice {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.shoppingBagalignPrice1 {
  display: flex;
  width: 90%;
  padding: 16px 24px 0px 172px;
  justify-content: space-between;
}

.verticalDivider {
  border: 1px double #e2e2e2;
}

.shoppingBagalignRingDop {
  display: flex;
  gap: 16px;
}

.shoppingBagRingSize {
  color: #000000;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.shoppingBagDropDown {
  width: 100px;
  height: 24px;
  display: flex;
  /* padding: 8px; */
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #ffffff;
}

.shoppingBagPromo {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.shoppingBagpromoCode {
  width: 70%;
  height: 32px;
  background-color: #ffffff;
  padding: 4px;
  margin: 16px 0px 0px 0px;
  border: none;
  font-size: 20px;
}

.shoppingBagpromoCodeSubmit {
  height: 40px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #30373e;
  color: #e2e2e2;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  margin: 16px 0px 0px 0px;
  cursor: pointer;
}

.shoppingBagDiv2divs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px 8px 0px;
}

.shoppingBagaSubHead {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
}

.shoppingBagSubPrice {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.shoppingBagCheckout {
  width: 100%;
  height: 42px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #30373e;
  margin: 16px 0px 0px 0px;
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .shoppingBagContainer {
    height: auto;
    padding: 72px 16px 32px 16px;
  }
  .shoppingBagheading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #000000;
    padding: 0px 0px 32px 0px;
  }

  .shoppingBagMainDiv {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .shoppingBagDiv1 {
    width: 100%;
  }

  .shoppingBagDiv2 {
    width: 100%;
  }

  .shoppingBagalign {
    display: flex;
    gap: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .shoppingBagImag {
    padding: 16px;
    background-color: #e9dfd2;
    width: 50%;
    height: auto;
  }

  .shoppingBagImag1 {
    height: 20px;
    width: 20px;
    margin: 0px 16px 0px 0px;
  }

  .shoppingBagalignDivs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 16px 0px 16px 0px;
  }

  .shoppingBagPName {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 200px;
  }

  .shoppingBagPDs {
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }
  .shoppingBagPDsDia{
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    margin-top: -40px;
  }

  .shoppingBagPrice {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
  }
  .shoppingBagPriceDia{
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
    /* padding-right: 150px; */
    /* background-color: #e9dfd2; */
    position: absolute;
    width: 26vw;
    right: 0;
  }

  .shoppingBagTotal {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .shoppingBagalignPrice {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .shoppingBagalignPrice1 {
    display: flex;
    width: 100%;
    padding: 32px 0px 0px 0px;
    justify-content: space-between;
  }

  .verticalDivider {
    border: 1px double #e2e2e2;
  }

  .shoppingBagalignRingDop {
    display: flex;
    gap: 16px;
  }

  .shoppingBagRingSize {
    color: #000000;
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
  }

  .shoppingBagDropDown {
    width: 100px;
    height: 12px;
    display: flex;
    /* padding: 8px; */
    justify-content: flex-end;
    align-items: center;
    background-color: #ffffff;
  }

  .shoppingBagPromo {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
  }

  .shoppingBagpromoCode {
    width: 70%;
    height: 22px;
    background-color: #ffffff;
    padding: 4px;
    margin: 16px 0px 0px 0px;
    border: none;
    font-size: 20px;
  }

  .shoppingBagpromoCodeSubmit {
    height: 30px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #30373e;
    color: #e2e2e2;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    margin: 16px 0px 0px 0px;
    cursor: pointer;
  }

  .shoppingBagDiv2divs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0px 8px 0px;
  }

  .shoppingBagaSubHead {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
  }

  .shoppingBagSubPrice {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .shoppingBagCheckout {
    width: 100%;
    height: 32px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #30373e;
    margin: 16px 0px 0px 0px;
    cursor: pointer;
  }
}
.shoppingBagquatitiy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.shoppingBagquantityButton {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
}

.shoppingBagquantity {
  margin: 0 5px;
  font-size: 1rem;
  font-weight: bold;
}