.wr3Container {
  height: auto;
  padding: 0px 0px 0px 0px;
  position: relative;
  display: flex;
  align-items: center;
}

.wr3Img {
  width: 100%;
}

.wr3Div {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: auto;
  left: 123px;
}

.wr3head {
  font-family: Alike;
  font-size: 42px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 30%;
}

.wr3texts {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
  width: 35%;
}

.wr3Btn {
  font-family: Alike;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 30%;
  border: none;
  padding: 4px 0px 4px 0px;
  margin: 16px 0px 0px 0px;
  background-color: #ffffff;
}

@media screen and (max-width: 430px) {
  .wr3Container {
    height: auto;
    padding: 0px 0px 0px 0px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .wr3Img {
    width: 100%;
  }

  .wr3Div {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: auto;
    left: 16px;
  }

  .wr3head {
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    width: 50%;
  }

  .wr3texts {
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #30373e;
    width: 60%;
  }

  .wr3Btn {
    font-family: Alike;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 30%;
    border: none;
    padding: 4px 0px 4px 0px;
    margin: 0px 0px 0px 0px;
    background-color: #ffffff;
  }
}
