  .homeDivSD {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .homeDiv12SD {
    width: 30%;
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 16px;
    left: 123px;
    z-index: 0;
  }
  
  .homeHeadSD {
    font-family: Alike;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    margin: auto 0px auto 0px;
  }
  
  .homeTextSD {
    font-family: Open Sans;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.015em;
    text-align: left;
    color: #30373e;
  }
  
  .homeBtnSD {
    width: 70%;
    background-color: #fff;
    border: none;
    height: 42px;
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.025em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    color: #30373e;
  }
  
  .homeBtnSD:hover {
    opacity: 0.9;
    background-color: #f8fafd;
  }

  .spinnerDiv{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .sdLabelCheckText{
    font-family: Open Sans Hebrew;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #717171;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .sdCheckBox{
    height: 20px;
    width: 20px;
    border: 1px solid #CFCFCF;
  }
  
  @media only screen and (max-width: 430px) {
    .home1SD {
      padding: 32px 0px 0px 0px;
    }
  
    .homeDivSD {
      position: relative;
      display: flex;
      align-items: flex-start;
    }
  
    .homeDiv12SD {
      width: auto;
      display: flex;
      flex-direction: column;
      position: absolute;
      gap: 12px;
      left: auto;
      top: 2rem;
      width: auto;
      z-index: 0;
      align-items: center;
    }
  
    .homeHeadSD {
      font-family: Alike;
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.5rem;
      letter-spacing: 0.025em;
      text-align: center;
      color: #30373e;
      margin: 0px;
      width: 60%;
    }
  
    .homeTextSD {
      font-family: Open Sans;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.2rem;
      letter-spacing: 0.025em;
      text-align: justify;
      color: #30373e;
      width: 84%;
      margin: 0px;
    }
  
    .homeBtnSD {
      width: 60%;
      background-color: #fff;
      border: none;
      height: 2.5rem;
      font-family: Alike;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.025em;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #30373e;
    }
  }
  