.selectSetting4Con {
  padding: 0px 123px 32px 123px;
}

.selectSettingAlignDataABC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px double #eaeaea;
  gap: calc(3%);
  padding: 16px 16px 16px 16px;
}

.selectSettingDiv123 {
  width: 10%;
  text-align: center;
  font-family: open sans;
  letter-spacing: 0.2px;
  color: #30373e;
  font-weight: 500;
}

.selectsetting4Attrvalue {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: open sans;
  letter-spacing: 0.2px;
  text-align: center;
  font-weight: 500;
  color: #30373e;
}

.selectSetting4heading {
  font-family: Alike;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0px 0px 32px 0px;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .selectSetting4Con {
    padding: 16px 16px 16px 16px;
  }

  .selectSettingAlignDataABC {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    border: none;
    gap: calc(4%);
  }

  .selectSettingDiv123 {
    width: calc(33%);
    text-align: center;
    font-family: open sans;
    letter-spacing: 0.2px;
    color: #30373e;
    font-weight: 500;
    color: #30373e;
  }

  .selectsetting4Attrvalue {
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: open sans;
    letter-spacing: 0.2px;
    text-align: center;
    font-weight: 500;
    color: #30373e;
  }

  .selectSetting4heading {
    font-family: Alike;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    padding: 0px 0px 16px 0px;
    color: #30373e;
  }
}
