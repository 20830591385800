.loginContainer {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.loginContainerDiv {
  position: absolute;
  right: 123px;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.loginHead {
  font-family: Alike;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.loginHeads {
  color: #30373e;
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: left;
}

.loginInput {
  width: 100%;
  margin: 16px 0px 0px 0px;
  border: none;
  height: 42px;
  width: 100%;
}

.aliggnTB {
  display: flex;
  gap: 16px;
  align-items: flex-end;
}

.otpGenerateBtn {
  background-color: #30373e;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  border: none;
  width: auto;
  padding: 4px 16px 4px 16px;
  cursor: pointer;
  margin: 8px 0px 0px 0px;
}

.otpGenerateBtn:active {
  opacity: 0.8;
}

.loginMessageText {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025em;
  text-align: right;
  color: #ed6874;
  padding: 8px 0px 0px 0px;
}

.errorMessageText{
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #ed6874;
  padding: 8px 0px 0px 0px;
  position: absolute;
}

.loginBTN {
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  background-color: #30373e;
  height: 42px;
  border: none;
  cursor: pointer;
  opacity: 1;
}

.loginBTN:active {
  opacity: 0.8;
}
@media screen and (max-width: 430px) {
  .loginHead{
    font-size: 24px;
    line-height: 30px;
  }
  .loginHeads{
    font-size: 16px;
    line-height: 20px;
  }
  .loginContainerDiv {
    position: absolute;
    right: 0;
    left: 0;
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    /* background-color: #30373e; */
    margin-left: 30px;
  }
}