.bpContainer1 {
  height: auto;
  position: relative;
}

.bpMainImg {
  width: 100%;
}

.bpContainer1AlignDiv {
  position: absolute;
  bottom: calc(12%);
  left: calc(64%);
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.bpMainHead {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.bpMainText {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  width: 80%;
}

.bpBtn1 {
  border: none;
  background-color: #fff;
  color: #30373e;
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 4px 96px 4px 96px;
}

.bpBtn2 {
  border: none;
  background-color: #30373e;
  color: #fff;
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 4px 55px 4px 55px;
}

.bpContainer2 {
  padding: 72px 123px 72px 123px;
}

.bp2MainHead {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.bp2AlignData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bp2Div {
  width: calc(24%);
  text-align: center;
}

.bp2Img {
  width: 100%;
}

.bp2Text {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .bpContainer2 {
    padding: 32px 16px 32px 16px;
  }

  .bp2MainHead {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }

  .bp2AlignData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
  }

  .bp2Div {
    width: calc(47%);
    text-align: center;
  }

  .bp2Img {
    width: 90%;
    margin: auto;
  }

  .bp2Text {
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }
}
