.sd5Container {
  padding: 0px 123px 72px 123px;
}

.sd5Head {
  font-family: Alike;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
}

.sd5AlignDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 32px 0px 0px 0px;
}

.sd5Div1 {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  border: 1px double #30373e;
  width: 32%;
  padding: 4px 0px 4px 0px;
}

.sd5Div2 {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #eaeaea;
  border: 1px double #30373e;
  width: 32%;
  padding: 4px 0px 4px 0px;
  background-color: #30373e;
}

@media screen and (max-width: 430px) {
  .sd5Container {
    padding: 0px 16px 32px 16px;
  }

  .sd5Head {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .sd5AlignDiv {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    padding: 32px 0px 0px 0px;
  }

  .sd5Div1 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    border: 1px double #30373e;
    width: 100%;
    padding: 4px 0px 4px 0px;
  }

  .sd5Div2 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #eaeaea;
    border: 1px double #30373e;
    width: 100%;
    padding: 4px 0px 4px 0px;
    background-color: #30373e;
  }
}
