.gsContainer1 {
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
}

.gsMainImg {
  width: 100%;
}

.gsContainer1AlignDiv {
  position: absolute;
  width: 50%;
  top: calc(30%);
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.gsMainHead {
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.gsMainText {
  font-family: Open Sans;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  width: 60%;
  color: #30373e;
}

.gsBtn1 {
  width: 80%;
  border: 2px solid #30373e;
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 10px 0px 10px 0px;
  background-color: #fff;
}

.gsBtn2 {
  width: 80%;
  background-color: #30373e;
  font-family: Alike;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fff;
  border: none;
  padding: 10px 0px 10px 0px;
}

.gsContainer2 {
  padding: 72px 123px 72px 123px;
}

.gs2MainHead {
  font-family: Alike;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.gs2AlignData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.gs2Div {
  width: calc(24%);
  text-align: center;
}

.gs2Img {
  width: 100%;
}

.gs2Text {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #30373e;
}

@media screen and (max-width: 430px) {
  .gsContainer1 {
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
  }

  .gsMainImg {
    width: 100%;
  }

  .gsContainer1AlignDiv {
    position: absolute;
    width: auto;
    top: 32px;
    left: auto;
    right: auto;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
  }

  .gsMainHead {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
  }

  .gsMainText {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    width: 90%;
    color: #30373e;
  }

  .gsBtn1 {
    width: 60%;
    border: 2px solid #30373e;
    font-family: Alike;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.025em;
    text-align: center;
    padding: 4px 0px 4px 0px;
    background-color: #fff;
  }

  .gsBtn2 {
    width: 60%;
    background-color: #30373e;
    font-family: Alike;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #fff;
    border: none;
    padding: 4px 0px 4px 0px;
  }

  .gsContainer2 {
    padding: 32px 16px 32px 16px;
  }

  .gs2MainHead {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    padding: 0px 0px 32px 0px;
  }

  .gs2AlignData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .gs2Div {
    width: calc(24%);
    text-align: center;
  }

  .gs2Img {
    width: 90%;
    margin: auto;
  }

  .gs2Text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #30373e;
  }
}
