.dp3Container1 {
  height: auto;
  padding: 0px 123px 72px 123px;
  position: relative;
}

.dp3Container1Div {
  width: 100%;
}

.dp3Img1 {
  width: 100%;
  height: auto;
}

.dp3Img2 {
  width: 40%;
  position: absolute;
  top: 48px;
  left: 172px;
}

.dp3Container2 {
  height: auto;
}

.dp3Div {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dp3Div1 {
  position: absolute;
  left: 16px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dp3Head {
  font-family: Alike;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
}

.dp3Head1 {
  font-family: Alike;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: 8px 0px auto 0px;
  width: 90%;
}

.dp3Text1 {
  font-family: open sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  margin: auto 0px auto 0px;
  width: 90%;
}

@media screen and (max-width: 430px) {
  .dp3Container1 {
    height: auto;
    padding: 32px 32px 32px 32px;
    position: relative;
  }

  .dp3Container1Div {
    width: 100%;
  }

  .dp3Img1 {
    width: 100%;
    height: auto;
  }

  .dp3Img2 {
    width: 33%;
    position: absolute;
    top: 48px;
    left: 48px;
  }

  .dp3Container2 {
    height: auto;
  }

  .dp3Div {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .dp3Div1 {
    position: absolute;
    top: 16px;
    left: 16px;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .dp3Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    margin: auto 0px auto 0px;
  }

  .dp3Head1 {
    font-family: Alike;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    margin: 8px 0px auto 0px;
    width: 90%;
  }

  .dp3Text1 {
    font-family: open sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
    margin: auto 0px auto 0px;
    width: 90%;
  }
}
