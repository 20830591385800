.wr1Container {
  height: auto;
  padding: 156px 123px 72px 123px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.wr1Heading {
  color: #30373e;
  font-family: Alike;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5rem;
  letter-spacing: 0.025em;
  text-align: center;
}

.wr1texts {
  font-family: Open Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025em;
  text-align: center;
  color: #30373e;
  width: 60%;
}

@media screen and (max-width: 430px) {
  .wr1Container {
    height: auto;
    padding: 72px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  .wr1Heading {
    color: #30373e;
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.025em;
    text-align: center;
  }

  .wr1texts {
    font-family: Open Sans;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    width: 100%;
  }
}
