.home8Container {
  height: auto;
  padding: 72px 123px 72px 123px;
}

.home8Head {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.alignHome8Div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px 0px 8px 0px;
  align-items: center;
}

.home8Mhead {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
}

.alignHome8Data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 32px 0px 0px 0px;
}

@media screen and (max-width: 430px) {
  .home8Container {
    height: auto;
    padding: 32px 16px 32px 16px;
  }

  .home8Head {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .alignHome8Div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px 0px 8px 0px;
    align-items: center;
  }

  .home8Mhead {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-align: left;
    color: #30373e;
  }

  .alignHome8Data {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 0px 0px 0px;
  }
}
