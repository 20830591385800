.bBContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.bBContainerDiv {
  position: absolute;
  top: calc(30%);
  width: 40%;
  left: 123px;
}

.buyHead1 {
  font-family: Open Sans;
  font-size: 54px;
  font-style: italic;
  font-weight: 600;
  line-height: 81px;
  letter-spacing: 0em;
  text-align: left;
  color: #af636a;
}

.buyHead3 {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 75px;
  letter-spacing: 0em;
  text-align: left;
  color: #af636a;
}

.buyHead2 {
  font-family: Alike;
  font-size: 54px;
  font-weight: 400;
  line-height: 75px;
  letter-spacing: 0em;
  text-align: left;
  color: #30373e;
}

.buyBacContainer {
  padding: 72px 123px 72px 123px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.buyBacHeading {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #af636a;
  padding: 0px 0px 32px 0px;
}

.buyBacText {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #30373e;
}

.buyBacHeading1 {
  font-family: Alike;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #79242c;
  padding: 0px 0px 32px 0px;
}

.buyBacText1 {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #717171;
  padding: 16px 0px 0px 0px;
}

.buyBacPoints {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 16px 0px 16px 0px;
}

.buyBacPoint {
  padding: 4px 16px 4px 16px;
  background-color: #fff0f1;
  color: #af636a;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.015em;
  border-radius: 100%;
}

@media screen and (max-width: 430px) {
  .bBContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .bBContainerDiv {
    position: absolute;
    top: calc(12%);
    width: 80%;
    left: 16px;
  }

  .buyHead1 {
    font-family: Open Sans;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: #af636a;
  }

  .buyHead3 {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: #af636a;
  }

  .buyHead2 {
    font-family: Alike;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: #30373e;
  }

  .buyBacContainer {
    padding: 32px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .buyBacHeading {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #af636a;
    padding: 0px 0px 16px 0px;
  }

  .buyBacText {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #30373e;
  }

  .buyBacHeading1 {
    font-family: Alike;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #79242c;
    padding: 0px 0px 16px 0px;
  }

  .buyBacText1 {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #717171;
    padding: 16px 0px 0px 0px;
  }

  .buyBacPoints {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 16px 0px 16px 0px;
  }

  .buyBacPoint {
    padding: 4px 16px 4px 16px;
    background-color: #fff0f1;
    color: #af636a;
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.015em;
    border-radius: 100%;
  }
}
