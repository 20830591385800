.home5Container {
  padding: 72px 123px 0px 123px;
}

.home5Heading {
  font-family: Alike;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.5rem;
  letter-spacing: 0.025em;
  text-align: left;
  color: #30373e;
  padding: 0px 0px 32px 0px;
}

.alignItemsHome5 {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.alignHome5ItemsDiv {
  width: calc(24%);
}

.home5ContainerImg {
  width: 100%;
}

.home5Mhead {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.home5Cre {
  font-family: Open Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

@media screen and (max-width: 430px) {
  .home5Container {
    padding: 32px 16px 0px 16px;
  }

  .home5Heading {
    font-family: Alike;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.025em;
    text-align: center;
    color: #30373e;
    padding: 0px 0px 16px 0px;
  }

  .alignItemsHome5 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .alignHome5ItemsDiv {
    width: calc(40%);
    margin: auto;
  }

  .home5ContainerImg {
    width: 90%;
    margin: auto;
  }

  .home5Mhead {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }

  .home5Cre {
    font-family: Open Sans;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
  }
}
