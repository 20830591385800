.jw2Conat {
  display: flex;
  padding: 0px 0px 72px 0px;
  justify-content: space-between;
}

.jw2Img {
  width: 49%;
}

@media screen and (max-width: 430px) {
  .jw2Conat {
    display: flex;
    padding: 0px 0px 32px 0px;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
  }

  .jw2Img {
    width: 100%;
  }
}
